import { Table } from "flowbite-react";
import React from "react";
import { useNavigate } from "react-router-dom";

type Props = {
  id: string;
  name: string;
  lecturer: string;
};
export const MobileTableItem = (props: Props) => {
  const { id, name, lecturer } = props;
  const navigate = useNavigate();

  return (
    <Table.Row className="cursor-pointer hover:bg-slate-200 bg-white dark:border-gray-700 dark:bg-gray-800">
      <Table.Cell
        onClick={() => {
        navigate(`subjects/${id}`);
        window.scrollTo(0, 0);
        }}
        className="font-semibold text-gray-900 dark:text-white"
      >
        {name}
      </Table.Cell>
      <Table.Cell>{lecturer}</Table.Cell>
    </Table.Row>
  );
};
