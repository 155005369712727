import {
  collection,
  addDoc,
  query,
  where,
  getDocs,
  QuerySnapshot,
  DocumentData,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../App";
import { Ratings } from "./Ratings";
import { getTimeLabel } from "../utils/getTimeLabel";
import { Faculty, RatingCategory } from "../types";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "../utils/useMedia";
import { Spinner } from "flowbite-react";

type Props = {
  itemId: string;
};

export const CommentCard = (props: Props) => {
  const { itemId } = props;
  const { t } = useTranslation();
  const matches = useMediaQuery("(min-width: 1024px)");
  const matches2 = useMediaQuery("(min-width: 384px)");
  const [comment, setComment] = useState<string>("");
  const [faculty, setFaculty] = useState<string>("None");
  const [comments, setComments] = useState<any[]>([]);
  const [commentsLoading, setCommentsLoading] = useState<boolean>(true);

  async function getComments() {
    if (itemId) {
      const q = query(
        collection(db, "comments"),
        where("subjectId", "==", itemId)
      );

      let querySnapshot: QuerySnapshot<DocumentData>;
      querySnapshot = await getDocs(q);

      let allComments: any[] = [];
      querySnapshot.forEach((snap) => {
        // console.log(snap.data());
        const { content, faculty, timestamp } = snap.data();
        allComments.push({
          id: snap.id,
          content,
          faculty,
          timestamp,
        });
      });

      // console.log(allComments)
      setComments(allComments.filter(com => com.content !== 'text'));
    }
  }

  const writeData = async (content: string) => {
    await addDoc(collection(db, "comments"), {
      subjectId: itemId,
      content,
      faculty,
      timestamp: new Date(),
    });
    setComment("");
    // toast(t("detailsScreen.toast"), {
    //   position: "top-right",
    //   autoClose: 5000,
    //   hideProgressBar: false,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   progress: undefined,
    //   type: "success",
    // });
  };

  useEffect(() => {
    getComments().then(() => setCommentsLoading(false));
  }, []);


  const handleCommentSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await writeData(comment);
    getComments();
  };

  return (
    <div
      className={`mb-24 mx-auto w-fit min-w-[75%] block p-6 bg-white border border-gray-200 shadow-md  dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 ${matches ? "w-min" : "max-w-sm "
        } ${matches2 ? "rounded-lg" : ""}`}
    >
      <div
        className={
          matches
            ? `grid grid-cols-2 gap-4 w-max`
            : `grid grid-cols-1 min-w-min`
        }
      >
        {/* TODO: Kategorijas reik sugalvot */}
        <Ratings category={RatingCategory.c1} itemId={itemId} />
        <Ratings category={RatingCategory.c2} itemId={itemId} addTooltip />
        <Ratings category={RatingCategory.c3} itemId={itemId} />
        <Ratings category={RatingCategory.c4} itemId={itemId} />
      </div>
      <hr className="mt-6 border border-gray-100"></hr>
      <div className="mt-6 mb-12">
        <form
          onSubmit={(event) => {
            handleCommentSubmit(event);
          }}
        >
          <div className=" mb-4 w-full bg-gray-50 rounded-lg border border-gray-200 dark:bg-gray-700 dark:border-gray-600">
            <div className="py-2 px-4 bg-white rounded-t-lg dark:bg-gray-800">
              <label htmlFor="comment" className="sr-only">
                {t("detailsScreen.commentCard.label")}
              </label>
              <textarea
                id="comment"
                rows={4}
                className="px-0 w-full text-sm text-gray-900 bg-white border-0 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                placeholder={t("detailsScreen.commentCard.placeholder")}
                required={true}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                maxLength={1000}
              />
            </div>
            <div className="flex justify-between items-center py-2 px-3 border-t dark:border-gray-600">
              <button
                type="submit"
                className="inline-flex items-center py-2.5 px-4 text-xs font-medium text-center text-white bg-rose-700 rounded-lg focus:ring-4 focus:ring-rose-200 dark:focus:ring-rose-900 hover:bg-rose-800"
              >
                {t("detailsScreen.commentCard.button")}
              </button>
              <select
                id="countries"
                className="ml-2 max-w-[100px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={faculty}
                onChange={(e) => setFaculty(e.target.value as Faculty)}
              >
                {(Object.keys(Faculty) as Array<keyof typeof Faculty>).map(
                  (fac) => {
                    if (fac === "None")
                      return <option key={"none"}>{"-"}</option>;
                    return <option key={fac}>{fac}</option>;
                  }
                )}
              </select>
            </div>
          </div>
        </form>
        <p className="ml-auto text-xs text-gray-500 dark:text-gray-400">
          {t("detailsScreen.commentCard.disclaimer.body")}
        </p>
      </div>
      <ol className=" border-gray-200 dark:border-gray-700">
        {commentsLoading ?


          <div className="mx-auto mb-4">
            <div className="p-4 bg-white rounded-lg border border-gray-200 shadow-sm dark:bg-gray-700 dark:border-gray-600">
              <Spinner className='mx-auto' color={'failure'} />          </div>
          </div>

          : comments.length ? (
            comments
              .sort(function compareFn(a, b) {
                return b.timestamp - a.timestamp;
              })
              .map((comm, idx) => {
                return (
                  <li className="mx-auto mb-4" key={comm?.id ?? idx}>
                    <div className="p-4 bg-white rounded-lg border border-gray-200 shadow-sm dark:bg-gray-700 dark:border-gray-600">
                      <div className="mb-1 text-sm font-normal text-gray-500 lex dark:text-gray-300">
                        {comm?.content ?? ""}
                      </div>
                      <div className="flex flex-row">
                        <time className="text-xs text-right font-normal text-gray-400 sm:mb-0">
                          {getTimeLabel(comm?.timestamp.toDate().valueOf(), t)}
                        </time>
                        <div className="mr-0 ml-auto text-xs font-normal text-gray-400 sm:order-last sm:mb-0">
                          {comm?.faculty ?? ""}
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })
          ) : (
            <li className="mx-auto mb-4">
              <div className="p-4 bg-white rounded-lg border border-gray-200 shadow-sm dark:bg-gray-700 dark:border-gray-600">
                <div className="mb-1 text-sm font-normal text-gray-500 lex dark:text-gray-300">
                  {t("detailsScreen.commentCard.first")}
                </div>
                <time className="text-xs text-right font-normal text-gray-400 sm:order-last sm:mb-0">
                  {" "}
                </time>
              </div>
            </li>
          )}
      </ol>
    </div>
  );
};
