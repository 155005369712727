import { addDoc, collection } from "firebase/firestore";
import { Modal } from "flowbite-react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Rating } from "react-simple-star-rating";
import { toast } from "react-toastify";
import { db } from "../App";

type Props = {
    displayModal: boolean;
    setDisplayModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FeedbackModal = ({ displayModal, setDisplayModal }: Props) => {
  const { t } = useTranslation();
  const [feedback, setFeedback] = useState<string>("");
  const [rating, setRating] = useState<number>(0);
  
  const writeData = async () => {
    await addDoc(collection(db, 'platform_feedback'), {
      feedback,
      rating,
      timestamp: new Date(),
    });
  };

  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    // console.log({feedback, rating})
    writeData();
    toast(t("feedbackModal.toast"), {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      type: "success",
    });    
    setDisplayModal(false);
  }

  // Catch Rating value
  const handleRating = (rate: number) => {
    setRating(rate);
  };
  
  const onClose = () => {
    setDisplayModal(!displayModal)
    // console.log(feedback);
  };

  return (
    <Modal show={displayModal} size="md" popup={true} onClose={onClose}>
      <Modal.Header />
      <Modal.Body>
        <div className="text-center">
          <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
            {t('feedbackModal.header')}
          </h3>
          <div>
            <div className="flex flex-row">
              <p className="mb-1 ml-1  text-sm text-gray-900 dark:text-white">
                {t('detailsScreen.rating.yourRating')}
              </p>
              {rating > 0 ? (
                <p className="mb-1 ml-1 font-bold  text-sm text-gray-900 dark:text-white">
                  {rating}
                </p>
              ) : (
                <></>
              )}
            </div>
            <div className="flex items-center h-max mb-5">
              <Rating
                onClick={handleRating}
                ratingValue={rating}
                size={20}
                transition
                fillColor="orange"
                emptyColor="gray"
              />
            </div>
          </div>
          <textarea
            id="comment"
            rows={4}
            className="mb-6 px-2  w-full text-sm text-gray-900 border-0 bg-gray-100 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
            placeholder={t("feedbackModal.placeholder")}
            required={true}
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            maxLength={500}
          /><button
          type="submit"
          onClick={(e) => handleSubmit(e)}
          className="inline-flex items-center py-2.5 px-4 text-xs font-medium text-center text-white bg-rose-700 rounded-lg focus:ring-4 focus:ring-rose-200 dark:focus:ring-rose-900 hover:bg-rose-800"
        >
          {t("feedbackModal.button")}
        </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
