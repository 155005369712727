import React from "react";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { FooterBar } from "./components/FooterBar";
import { ToastContainer } from "react-toastify";
import { NavBar } from "./components/NavBar";
import { DetailsScreen } from "./screens/DetailsScreen";
import { MainScreen } from "./screens/MainScreen";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import "react-toastify/dist/ReactToastify.css";
import { MaintenanceScreen } from "./screens/MaintenanceScreen";
import { ErrorScreen } from "./screens/ErrorScreen";
import { FirstTimeModal } from "./components/FirstTimeModal";
import { TimelineScreen } from "./screens/TimelineScreen";

const {
  REACT_APP_API_KEY,
  REACT_APP_STORAGE_BUCKET,
  REACT_APP_APP_ID,
  REACT_APP_SENDER_ID,
  REACT_APP_PROJECT_ID,
  REACT_APP_AUTH_DOMAIN,
  REACT_APP_MAINTENANCE_MODE,
  REACT_APP_DB_URL,
  NODE_ENV
} = process.env;

Sentry.init({
  dsn: "https://cf0a26eb21d74a9bb9f0561aea1602c0@o4504626835619840.ingest.sentry.io/4504626904760320",
  integrations: [new BrowserTracing()],
  environment: NODE_ENV,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const firebaseConfig = {
  apiKey: REACT_APP_API_KEY,
  authDomain: REACT_APP_AUTH_DOMAIN,
  databaseURL: REACT_APP_DB_URL,
  projectId: REACT_APP_PROJECT_ID,
  storageBucket: REACT_APP_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_SENDER_ID,
  appId: REACT_APP_APP_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app);
// enableIndexedDbPersistence(db);

function App() {
  const displayFirstTimeModal = document.cookie
    .split("; ")
    .find((row) => row.startsWith("modalShown="))
    ?.split("=")[1];

  if (Boolean(Number(REACT_APP_MAINTENANCE_MODE))) {
    return (
        <BrowserRouter>
          <NavBar />
          <MaintenanceScreen />
        </BrowserRouter>
    );
  }

  return (
    <>
      <div className="min-h-screen">
        {displayFirstTimeModal !== "true" && <FirstTimeModal />}
        <ToastContainer />
        <BrowserRouter>
          <NavBar />
          <Routes>
            <Route path="/" element={<MainScreen />} />
            <Route path="subjects/:itemId" element={<DetailsScreen />} />
            <Route path="timeline" element={<TimelineScreen />} />
            <Route path="*" element={<ErrorScreen />} />
          </Routes>
        </BrowserRouter>
      </div>
      <FooterBar />
    </>
  );
}

export default App;
