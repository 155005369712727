import { TextInput } from "flowbite-react";
import React from "react";
import { useTranslation } from "react-i18next";

type Props = {
    searchPhrase: string,
    setSearchPhrase: React.Dispatch<React.SetStateAction<string>>,
    screenSize: 'small' | 'large';
}
export const SearchBar = ({searchPhrase, setSearchPhrase, screenSize}: Props) => {
  // console.log(searchPhrase);
  const {t} = useTranslation();

  return (
    <div className={screenSize === 'large' ? `max-w-xl mx-auto mb-12` : `max-w-sm mx-auto px-12 -mt-20 mb-8`}>
        <TextInput
        id="small"
        type="text"
        sizing="sm"
        placeholder={t('mainScreen.searchBar')}
        value={searchPhrase}
        onChange={(e) => setSearchPhrase(e.target.value)}
        />
    </div>
  );
};
