import { Table } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MobileTableItem } from "./MobileTableItem";
import { SearchBar } from "./SearchBar";

export const MobileTableList = () => {
  const [subjects, setSubjects] = useState<any[]>([]);
  const [searchPhrase, setSearchPhrase] = useState<string>("");
  const { t } = useTranslation();
  const filteredSubjects = subjects.filter((subject) =>
    subject.name.match(new RegExp(searchPhrase, "i"))
  ).sort(function(a, b){
    let nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
    if (nameA < nameB) //sort string ascending
     return -1;
    if (nameA > nameB)
     return 1;
    return 0; //default return value (no sorting)
   });

  useEffect(() => {
    const def = t("subjects", { returnObjects: true }) as any[];
    setSubjects(
      Object.entries(def).map((value, _key) => {
        return { ...value[1], id: value[0] };
      })
    );
  }, [t]);

  return (
    <div className="w-screen">
      <SearchBar
        searchPhrase={searchPhrase}
        setSearchPhrase={setSearchPhrase}
        screenSize="small"
      />
      {filteredSubjects.length === 0 ? (
        <Table hoverable={true} striped={true}>
          <Table.Row>
            <Table.Cell>
              <div className="text-center">{t("mainScreen.table.empty")}</div>
            </Table.Cell>
          </Table.Row>
        </Table>
      ) : (
        <Table className="w-screen" hoverable={true} striped={true}>
          <Table.Head>
            <Table.HeadCell>
              <div className="flex items-center">
                {t("mainScreen.table.name")}
                {/* <a href="#">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="ml-1 w-3 h-3"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 320 512"
                >
                  <path d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z" />
                </svg>
              </a> */}
              </div>
            </Table.HeadCell>
            <Table.HeadCell>
              <div className="flex items-center">
                {t("mainScreen.table.lecturers")}
                {/* <a href="#">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="ml-1 w-3 h-3"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 320 512"
                >
                  <path d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z" />
                </svg>
              </a> */}
              </div>
            </Table.HeadCell>
          </Table.Head>
          <Table.Body className="divide-y">
            {filteredSubjects.map((subject, idx) => (
              <MobileTableItem
                key={subject.id}
                id={subject.id}
                name={subject.name}
                lecturer={subject.lecturers}
              />
            ))}
          </Table.Body>
        </Table>
      )}
    </div>
  );
};
