import React from "react";
import { Faculty } from "../types";

type Props = {
  faculty?: Faculty;
  size?: "small" | "large";
};
export const FacultyBadge = ({
  faculty = Faculty.None,
  size = "small",
}: Props) => {
  const dict = {
    CHGF: "cyan-600",
    EVAF: "orange-400",
    FLF: "teal-600",
    FILF: "sky-400",
    FF: "purple-700",
    GMC: "amber-700",
    IF: "stone-500",
    KNF: "violet-600",
    KOMF: "orange-500",
    MF: "red-700",
    ŠA: "gray-600",
    TSPMI: "emerald-600",
    TF: "yellow-700",
    VM: "rose-700",
    MIF: "blue-600",
  };

  return (
      <span
        className={
          size === "small"
            ? `inline-flex  items-center justify-items-center px-2 py-1 text-xs font-bold leading-none text-gray-100 bg-stone-700 rounded`
            : `max-w-max max-h-[50px] mt-4 inline-flex mx-6 items-center justify-center ml-4 px-2 py-1 text-3xl font-bold text-gray-100 bg-stone-700 rounded align-middle`
        }
      >
        {faculty}
      </span>
  );
};
