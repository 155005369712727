import { Table } from "flowbite-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Faculty } from "../types";
import { FacultyBadge } from "./FacultyBadge";

type Props = {
  id: string;
  name: string;
  faculty: Faculty;
  lecturer: string;
};
export const TableItem = (props: Props) => {
  const { id, name, faculty, lecturer } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Table.Row className="bg-white hover:bg-slate-200 dark:border-gray-700 dark:bg-gray-800">
      <Table.Cell className=" font-semibold text-gray-900 dark:text-white">
        {name}
      </Table.Cell>
      <Table.Cell>
        <FacultyBadge faculty={faculty} />
      </Table.Cell>
      <Table.Cell>{lecturer}</Table.Cell>
      <Table.Cell>
        <div
          onClick={() => {
            navigate(`subjects/${id}`);
            window.scrollTo(0, 0);
          }}
          className="font-medium text-blue-600 hover:underline dark:text-blue-500"
        >
          {t("mainScreen.table.button")}
        </div>
      </Table.Cell>
    </Table.Row>
  );
};
