import { TFunction } from "react-i18next";

export const getTimeLabel = (
  timestamp: number,
  t: TFunction<"translation", undefined>
) => {
  const diff_sec = Math.floor((new Date().valueOf() - timestamp) / 1000);
  if (diff_sec < 60) {
    return t("detailsScreen.timing.lessThanMin");
  }
  if (diff_sec < 60 * 60) {
    const diff_min = Math.floor(diff_sec / 60);
    if (diff_min % 10 === 1) {
      return (
        t("detailsScreen.timing.generic", { x: diff_min }) +
        t("detailsScreen.timing.minute1")
      );
    }
    if (diff_min > 10 && diff_min < 21) {
      return (
        t("detailsScreen.timing.generic", { x: diff_min }) +
        t("detailsScreen.timing.minute2")
      );
    } else
      return (
        t("detailsScreen.timing.generic", { x: diff_min }) +
        t("detailsScreen.timing.minute3")
      );
  } else if (diff_sec < 60 * 60 * 24) {
    const diff_h = Math.floor(diff_sec / 60 / 60);
    const string_return = `prieš ${diff_h} `;

    if (diff_h % 10 === 1) {
      return (
        t("detailsScreen.timing.generic", { x: diff_h }) +
        t("detailsScreen.timing.hour1")
      );
    }
    if (diff_h > 10 && diff_h < 21) {
      return (
        t("detailsScreen.timing.generic", { x: diff_h }) +
        t("detailsScreen.timing.hour2")
      );
    } else
      return (
        t("detailsScreen.timing.generic", { x: diff_h }) +
        t("detailsScreen.timing.hour3")
      );
  } else {
    const diff_days = Math.floor(diff_sec / 60 / 60 / 24);

    if (diff_days === 1) {
      return t("detailsScreen.timing.dayAgo");
    }
    if (diff_days > 1 && diff_days < 3) {
      return t("detailsScreen.timing.moreThanDayAgo");
    }
    if (diff_days > 2 && diff_days < 7) {
      return t("detailsScreen.timing.fewDaysAgo");
    }
    if (diff_days > 7 && diff_days < 14) {
      return t("detailsScreen.timing.weekAgo");
    }
    if (diff_days > 13 && diff_days < 31) {
      return t("detailsScreen.timing.fewWeeksAgo");
    }
    if (diff_days > 30 && diff_days < 32) {
      return t("detailsScreen.timing.monthAgo");
    }
    if (diff_days > 31 && diff_days < 30 * 4) {
      return t("detailsScreen.timing.fewMonthsAgo");
    }
    if (diff_days > 30 * 3 && diff_days < 30 * 7) {
      return t("detailsScreen.timing.halfYearAgo");
    }
    if (diff_days > 30 * 6 && diff_days < 30 * 12) {
      return t("detailsScreen.timing.lessThanYearAgo");
    }
    if (diff_days > 30 * 11 && diff_days < 30 * 24) {
      return t("detailsScreen.timing.yearAgo");
    }
    const diff_years = Math.floor(diff_sec / 60 / 60 / 24 / 365);
    if (diff_years > 9 && diff_years < 21) {
      return t("detailsScreen.timing.xYearsAgo1", { years: diff_years });
    }
    return t("detailsScreen.timing.xYearsAgo2", { years: diff_years });
  }
};
