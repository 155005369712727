import React from "react"
import { useTranslation } from "react-i18next";
import { HiHeart } from "react-icons/hi";
import { useMediaQuery } from "../utils/useMedia";

export const TimelineComponent = () => {
  const { t } = useTranslation();
  const matches = useMediaQuery("(min-width: 1024px)");
  const matches2 = useMediaQuery("(min-width: 384px)");
  const matches3 = useMediaQuery("(min-width: 640px)");

  return (
    <div
      className={matches3 ? `mb-24 mx-auto w-fit min-w-[75%] block py-8 px-12 bg-white border border-gray-200 shadow-md  dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 ${matches ? "w-min" : "max-w-sm "
        } ${matches2 ? "rounded-lg" : ""}` : 'py-8 px-12'}
    >
      <ol className="relative border-l border-gray-200 dark:border-gray-700">
        <li className="mb-10 ml-6">
          <span className="flex absolute -left-3 justify-center items-center w-6 h-6 bg-rose-200 rounded-full ring-8 ring-white dark:ring-gray-900 dark:bg-rose-900">
            <svg aria-hidden="true" className="w-3 h-3 text-rose-600 dark:text-rose-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clip-rule="evenodd"></path></svg>
          </span>
          <h3 className="mb-1 text-lg font-semibold text-gray-900 dark:text-white">
            {t('timelineScreen.point1.title')}
          </h3>
          <time className="block mb-2 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
            {t('timelineScreen.point1.time')}
          </time>
          <p className="text-base font-normal text-gray-500 dark:text-gray-400">
            {t('timelineScreen.point1.description')}
          </p>
        </li>
        <li className="mb-10 ml-6">
          <span className="flex absolute -left-3 justify-center items-center w-6 h-6 bg-rose-200 rounded-full ring-8 ring-white dark:ring-gray-900 dark:bg-rose-900">
            <svg aria-hidden="true" className="w-3 h-3 text-rose-600 dark:text-rose-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clip-rule="evenodd"></path></svg>
          </span>
          <h3 className="mb-1 text-lg font-semibold text-gray-900 dark:text-white">
            {t('timelineScreen.point2.title')}
          </h3>
          <time className="block mb-2 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
            {t('timelineScreen.point2.time')}
          </time>
          <p className="text-base font-normal text-gray-500 dark:text-gray-400">
            {t('timelineScreen.point2.description')}
          </p>
        </li>
          <li className="mb-10 ml-6">
            <span className="flex absolute -left-4 justify-center items-center w-8 h-8 bg-rose-200 rounded-full ring-8 ring-white dark:ring-gray-900 dark:bg-rose-900">
              <HiHeart color='red' />
            </span>
            <h3 className="ml-2 flex items-center mb-1 text-xl font-bold text-gray-900 dark:text-white">
              {t('timelineScreen.point3.title')}
              <span className="bg-rose-100 text-rose-800 text-sm font-medium mt-1 mr-2 px-2.5 py-0.5 rounded dark:bg-rose-200 dark:text-rose-800 ml-3">
                {t('timelineScreen.point3.badge')}
              </span></h3>
            <time className="ml-2 block mb-2 text-sm font-semibold leading-none text-gray-400 dark:text-gray-500">
              {t('timelineScreen.point3.time')}
            </time>
            <p className="ml-2 mb-4 text-md font-normal text-gray-500 dark:text-gray-400">
              {t('timelineScreen.point3.description')}
            </p>
            <a href="https://www.facebook.com/marius.somka" target="_blank" className="inline-flex items-center py-2 px-4 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-rose-700 focus:z-10 focus:ring-4 focus:outline-none focus:ring-gray-200 focus:text-rose-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-gray-700">
              {t('timelineScreen.point3.action')}
              <svg className="ml-2 w-3 h-3" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg></a>        
          </li>
        <li className="mb-10 ml-6">
          <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>          <h3 className="flex items-center mb-1 text-lg font-semibold text-gray-900 dark:text-white">
            {t('timelineScreen.point4.title')}
            <span className="bg-stone-100 text-stone-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-stone-200 dark:text-stone-800 ml-3">
              {t('timelineScreen.point4.badge')}
            </span></h3>
          <time className="block mb-2 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
            {t('timelineScreen.point4.time')}
          </time>
          <p className="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
            {t('timelineScreen.point4.description')}          </p>
        </li>
        <li className="mb-10 ml-6">
          <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
          <h3 className="flex items-center mb-1 text-lg font-semibold text-gray-900 dark:text-white">
            {t('timelineScreen.point5.title')}
            <span className="bg-stone-100 text-stone-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-stone-200 dark:text-stone-800 ml-3">
              {t('timelineScreen.point5.badge')}
            </span></h3>
          <time className="block mb-2 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
            {t('timelineScreen.point5.time')}
          </time>
          <p className="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
            {t('timelineScreen.point5.description')}
          </p>
        </li>
        <li className="ml-6">
          <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
          <h3 className="flex items-center mb-1 text-lg font-semibold text-gray-900 dark:text-white">
            {t('timelineScreen.point6.title')}
            <span className="bg-stone-100 text-stone-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-stone-200 dark:text-stone-800 ml-3">
              {t('timelineScreen.point6.badge')}
            </span></h3>
          <time className="block mb-2 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
            {t('timelineScreen.point6.time')}
          </time>
          <p className="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
            {t('timelineScreen.point6.description')}
          </p>
        </li>
      </ol>

    </div>
  )
}