export type Subject = {
  id: string;
  name: string;
  faculty: Faculty;
  credits: number;
  lecturers: string;
  language: Language.Lithuanian;
  exam: boolean;
  hours: number;
  link: string;
};

export enum Language {
  Lithuanian = "lt",
  English = "en",
  Russian = "ru",
}

export enum Faculty {
    CHGF= 'CHGF',
    EVAF= 'EVAF',
    FLF= 'FLF',
    FILF= 'FILF',
    FF= 'FF',
    GMC= 'GMC',
    IF= 'IF',
    KNF= 'KNF',
    KOMF= 'KOMF',
    MF= 'MF',
    ŠA= 'ŠA',
    TSPMI= 'TSPMI',
    TF= 'TF',
    VM= 'VM',
    MIF= 'MIF',
    None = '',
}

export enum RatingCategory {
  c1 = 'category1',
  c2 = 'category2',
  c3 = 'category3',
  c4 = 'category4'
}