export const subjects_lt = {
  "07w8CqQNuWpICh01jgF2": {
    "exam": true,
    "name": "Socialinė antreprenerystė",
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=9E70C70F17FEF546B6CFA0687C57E097114F4220DCAFD856",
    "faculty": "VM",
    "delivery": "blended",
    "lecturers": "Asist. Dr. Danguolė Oželienė Asist. Dr. Ieva Adomaitytė Subačienė  Andželika Rusteikienė",
    "hours": 48,
    "credits": 5,
    "language": "lt"
  },
  "0X4ar1ktFAmWvRBErtcN": {
    "credits": 5,
    "name": "Rytų filosofija ir etika",
    "delivery": "face",
    "exam": true,
    "faculty": "FILF",
    "hours": 48,
    "lecturers": "Asist. Dr. Vladimir Korobov Asist. Dr. Vladimir Korobov",
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=FA5252C555D73F2A617865E024D749FF482B95EF570C5A67",
    "language": "lt"
  },
  "0rCt7927gvbZJsm3fqCQ": {
    "language": "en",
    "credits": 5,
    "delivery": "face",
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=74E4DD7861906ADC9F595AE464DEBA731658966791AC947A",
    "exam": true,
    "lecturers": "M. D. Dr. Thomas William Peak M. D. Dr. Thomas William Peak",
    "name": "Tarptautinės bendruomenės atsakas į masinius žmogaus teisių pažeidimus",
    "hours": 48,
    "faculty": "TSPMI"
  },
  "1VxJ46m7HQi2Ok0ewLSl": {
    "exam": true,
    "lecturers": "Doc. Dr. Audronė Rimkutė Doc. Dr. Audronė Rimkutė",
    "language": "lt",
    "name": "Kultūra ir vartojimas",
    "credits": 5,
    "delivery": "online",
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=392519892AED70B989BEECE7328FF442B4061B650C3D8FF4",
    "hours": 48,
    "faculty": "KNF"
  },
  "1rSh7yKbjpEl97yME7R8": {
    "language": "lt",
    "lecturers": "dr. Greta Kaluževičiūtė dr. Greta Kaluževičiūtė",
    "delivery": "online",
    "name": "Asmenybė ir jos tapsmas",
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=6D4BE63609AD5009EF4D7B829ADA1C365B1D60BA4C4FA7E7",
    "credits": 5,
    "exam": true,
    "faculty": "FILF",
    "hours": 48
  },
  "20gxoD5oukufFmjahHoe": {
    "delivery": "online",
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=8E33CE7878CC4B625BBC6F271B4024D850C2EEBD98C01807",
    "faculty": "VM",
    "credits": 5,
    "language": "lt",
    "exam": true,
    "lecturers": "Prof. Dr. Tadas Gudaitis Prof. Dr. Tadas Gudaitis",
    "name": "Tvari organizacijų veikla",
    "hours": 48
  },
  "4JC7mpOaRKQzpuz3uUjh": {
    "lecturers": "Doc. Dr. Inga Vidugirytė-Pakerienė",
    "faculty": "FLF",
    "credits": 5,
    "exam": true,
    "name": "Juoko kultūra",
    "language": "lt",
    "hours": 48,
    "delivery": "face",
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=576C9FCC5E119A23F4F39A566E616DCBF5538B30CE79E9B7"
  },
  "4uRINedUNyBVDEd2QcJQ": {
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=99A84C7D8EFAF580F12374531011A0682BFCA54366B7066A",
    "lecturers": "Asist. Dr. Renata Bikauskaitė Asist. Dr. Renata Bikauskaitė",
    "faculty": "FILF",
    "exam": true,
    "delivery": "online",
    "name": "Klasikinė etika ir modernioji lyderystė",
    "hours": 48,
    "credits": 5,
    "language": "lt"
  },
  "5OjVJvnRqmyoCFSJDNSl": {
    "faculty": "KOMF",
    "name": "Lygios galimybės ir nediskriminavimas",
    "credits": 5,
    "lecturers": "Doc. Dr. Ilona Michailovič Doc. Dr. Lijana Stundžė Asist. Dr. Reda Adomaitienė",
    "exam": true,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=2EC064E538A11AD929234F12D178C7FECAC1137DC429B937",
    "language": "lt",
    "delivery": "face",
    "hours": 48
  },
  "9xBobzqje9bqpuTKLUs6": {
    "lecturers": "Doc. Dr. Roma Adomaitienė",
    "exam": true,
    "hours": 48,
    "faculty": "EVAF",
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=7478B46F8A397C61EF833DE1B896208813EFBCC4B68744BC",
    "delivery": "online",
    "credits": 5,
    "language": "lt",
    "name": "Kokybės vadyba"
  },
  "A1Eh4vmxho5Ce9Vws8qX": {
    "credits": 5,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=C2373106E83947AF1873B50395B30545D47296A912F4E8A9",
    "exam": true,
    "name": "Derybų kalba",
    "hours": 48,
    "delivery": "online",
    "language": "lt",
    "lecturers": "Doc. Dr. Skirmantė Biržietienė Doc. Dr. Eglė Gabrėnaitė",
    "faculty": "KNF"
  },
  "AskI9cCme9ZchT7XUeaD": {
    "hours": 48,
    "exam": true,
    "credits": 5,
    "lecturers": "Doc. Dr. Deividas Šlekys Doc. Dr. Deividas Šlekys",
    "delivery": "online",
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=3C9EE76E3F8686193689BCE7C1887C7F8564E23FE5499709",
    "faculty": "TSPMI",
    "name": "Karo studijos",
    "language": "lt"
  },
  "B2NZLG7XpCwEBzNbGeLa": {
    "credits": 5,
    "exam": true,
    "faculty": "TSPMI",
    "language": "lt",
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=52DA1F1396F3A2214121256110F11FB0866A4F3764DE75A8",
    "delivery": "online",
    "hours": 48,
    "name": "Menas ir politika",
    "lecturers": "Prof. Dr. (HP) Alvydas Jokubaitis J. Asist. Dr. Viktorija Rimaitė-Beržiūnienė"
  },
  "Ctoa3IYAEjHz87Z1klKW": {
    "lecturers": "Dr. Aušra Širvinskienė  Jūratė Litvinaitė  Laura Daukšaitė  Rima Žilinskaitė",
    "exam": true,
    "language": "lt",
    "delivery": "face",
    "name": "Sociologija",
    "credits": 5,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=9E3FAF9CE695983BFC6FA3DB8DF08EBC6D192F436F1A8BE4",
    "faculty": "FILF",
    "hours": 48
  },
  "Cw2RQ9Ut8T9rT3X1fMhU": {
    "name": "Psichologija",
    "hours": 48,
    "faculty": "FILF",
    "lecturers": "Doc. Dr. Irena Žukauskaitė Dr. Robertas Povilaitis",
    "language": "lt",
    "delivery": "online",
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=41B6E922BC43DF7B1334A0866D02EE73ED51171E32328CF6",
    "exam": true,
    "credits": 5
  },
  "Eke3wXBHQ1wk1Gstfccp": {
    "faculty": "FILF",
    "exam": true,
    "name": "Globalizacija: raida ir pasekmės",
    "language": "lt",
    "lecturers": "Lekt. Dr. Julija Tuleikytė Lekt. Dr. Julija Tuleikytė",
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=9D28F2544C224CF01B6DADCE76D7F16768C274754B10E53A",
    "delivery": "online",
    "credits": 5
  },
  "FGrUCxwWx68pRQPPtRQI": {
    "exam": true,
    "delivery": "online",
    "hours": 48,
    "name": "Paveldas: nuo globalumo iki vietos kultūros",
    "language": "lt",
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=61297DDE1DDE03EAA4E75A678B9F6444C3C4AC763D484334",
    "lecturers": "Doc. Dr. Salvijus Kulevičius Prof. Dr. Irena Vaišvilaitė",
    "faculty": "IF",
    "credits": 5
  },
  "FUxjUlKM3vrxJcbYjnpd": {
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=DE4B0BD92C14C68B7FBDDEC7DD9D7EF687BB7A0C3E8A40A8",
    "hours": 48,
    "name": "Gyvūnų elgsenos pagrindai",
    "credits": 5,
    "delivery": "online",
    "faculty": "GMC",
    "lecturers": "Asist. Dr. Irena Nedveckytė Asist. Dr. Irena Nedveckytė",
    "exam": true,
    "language": "lt"
  },
  "IMX8bmhOfazPhuppoRmD": {
    "delivery": "blended",
    "faculty": "KNF",
    "exam": true,
    "language": "en",
    "hours": 48,
    "name": "Pažinimas, mąstymas ir kalba",
    "credits": 5,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=A99EBA5F766770184786152CF55CA4F5E8F692B8A7976FCF",
    "lecturers": "Doc. Dr. Jurgita Kerevičienė Doc. Dr. Jurgita Kerevičienė"
  },
  "J3LpEjwxM6VtxezNDJ4P": {
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=5D67AD7C77FE6F02DDC30A3A0A15F9D2A7797DDD184406B7",
    "delivery": "face",
    "name": "Lyčių studijos",
    "exam": true,
    "hours": 50,
    "language": "lt",
    "faculty": "KOMF",
    "lecturers": "Doc. Dr. Lijana Stundžė Doc. Dr. Ilona MichailovičDoc. Dr. Aurelija NovelskaitėAsist. Dr. Ernesta Platūkytė",
    "credits": 5
  },
  "JQmLcq6Lbtg0fVyBhqKK": {
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=9D2FD7A760CB845945780A4E46BF4AC32DAB88E2A2CA56DD",
    "delivery": "face",
    "credits": 5,
    "lecturers": "Asist. Dr. Olga Zamalijeva Dr. Tomas Maceina",
    "name": "Taikomoji socialinė psichologija",
    "language": "lt",
    "exam": true,
    "hours": 48,
    "faculty": "FILF"
  },
  "KlJhj65ELwM2zb0bnWHf": {
    "language": "en",
    "exam": true,
    "delivery": "online",
    "lecturers": "Asist. Dr. Laimutė Jakavonytė Asist. Dr. Laimutė Jakavonytė",
    "credits": 5,
    "faculty": "FILF",
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=39C5C3A62C0064B517ED4C5CE31D42D6A94320FEB34396CC",
    "name": "Filosofija",
    "hours": 48
  },
  "LiY0yIxnEzSJf6ElDD5R": {
    "language": "lt",
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=BA926F549C576DA9086D6557C0002528C3C19C41F4897B67",
    "name": "Psichoaktyviosios medžiagos: poveikis, priklausomybė ir prevencija",
    "hours": 48,
    "faculty": "MF",
    "delivery": "online",
    "exam": true,
    "credits": 5,
    "lecturers": "Doc. Dr. Robertas Badaras Asist. Dr. Ingrida LisauskienėProf. Dr. (HP) Osvaldas Rukšėnas Prof. Dr. Alvydas Navickas Prof. Dr. Laimutė BulotaitėPartnerystės doc.   Taurimas Valys"
  },
  "MD4y9XE0alDH1hzXaFSG": {
    "hours": 48,
    "delivery": "online",
    "exam": true,
    "name": "Europos istorija: epochos ir regionai",
    "faculty": "IF",
    "lecturers": "Asist. Dr. Nerijus Babinskas Asist. Dr. Nerijus Babinskas",
    "language": "lt",
    "credits": 5,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=6B4A6CDE009162A938F3A508B752BE7D3C78F5322F796E64"
  },
  "Mb39CNkaZFsgNsBjYtEj": {
    "exam": true,
    "credits": 5,
    "hours": 50,
    "lecturers": "Prof. Dr. Asta Mikalauskienė Prof. Dr. Skaidrė Žičkienė",
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=9E12F9E7243543C2B18DE79566790CF30AC7D77D5E18BE2A",
    "language": "en",
    "delivery": "online",
    "faculty": "ŠA",
    "name": "Darnus vystymasis"
  },
  "NIqYhLAWiNz6mSCFtS8r": {
    "faculty": "EVAF",
    "lecturers": "Asist. Dr. Darius Ruželė",
    "exam": true,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=7250DC9002DFCB9B8B9092BC833CD896F47FB5CF893AA0C7",
    "delivery": "online",
    "language": "lt",
    "name": "Organizacijų vadyba",
    "credits": 5
  },
  "NvuBq3K2gresRXhB9ZgL": {
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=6CCD8162E9CB849F7F11705039E76CE9B951A23267C16479",
    "lecturers": "Prof. Dr. Asta Mikalauskienė Prof. Dr. Asta Mikalauskienė",
    "credits": 5,
    "exam": true,
    "faculty": "KNF",
    "language": "lt",
    "name": "Darnus vystymasis",
    "delivery": "online",
    "hours": 48
  },
  "OoVRTx1fEw6Fg0BIhInB": {
    "exam": true,
    "delivery": "online",
    "lecturers": "Asist. Dr. Greta Kaluževičiūtė  Austėja Agnietė Čepulienė  Gitana Naudužienė",
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=DC7E2F72FDA82BA2A35DA6DA9CB0697D48732B2EBB32B86E",
    "hours": 48,
    "credits": 5,
    "faculty": "FILF",
    "name": "Bendravimo psichologija",
    "language": "lt"
  },
  "PH6qdioq80y7MVRskct6": {
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=12DF7A1C17BB509E174C0FE600857A7B3918185951E4C965",
    "name": "Verslumas",
    "lecturers": "Asist. Dr. Danguolė Oželienė Asist. Dr. Danguolė Oželienė",
    "hours": 64,
    "credits": 5,
    "faculty": "VM",
    "language": "lt",
    "exam": true,
    "delivery": "blended"
  },
  "Px2eIzJMgV6yz9PnR7SP": {
    "exam": true,
    "language": "lt",
    "name": "Islamas: nuo tradicinio iki radikalaus",
    "lecturers": "Asist. Dr. Ieva Koreivaitė Asist. Dr. Ieva Koreivaitė",
    "faculty": "TSPMI",
    "delivery": "online",
    "credits": 5,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=6D10D411AB3D2DF689C91064921C2C47264F389D9EF4E9F9",
    "hours": 48
  },
  "Q1TMmggslm3vMDpHRPBx": {
    "credits": 5,
    "lecturers": "Prof. Dr. (HP) Gediminas Vitkus Prof. Dr. (HP) Gediminas Vitkus",
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=AFC0B7DDDA6AE38E11E5877B661BEDFE0415B5253746944C",
    "faculty": "TSPMI",
    "language": "lt",
    "hours": 48,
    "exam": true,
    "delivery": "online",
    "name": "Europos Sąjungos studijų pagrindai"
  },
  "QLelzZcbZc42HEYQwQU6": {
    "hours": 60,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=FEDC8D39ED87AB4D4CF2C1DFD5149019082AF37E32F02D3D",
    "delivery": "online",
    "credits": 5,
    "exam": true,
    "faculty": "ŠA",
    "name": "Retorika",
    "language": "lt",
    "lecturers": "Prof. Dr. Irena Smetonienė Doc. Dr. Jolanta Vaskelienė"
  },
  "R23GxscbFk7DWdinTrIs": {
    "exam": true,
    "faculty": "FILF",
    "language": "en",
    "delivery": "online",
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=2D676BD00AC7B5775E215FE410618EA167EDB488DC4771DE",
    "credits": 5,
    "lecturers": "asist. dr. Joshua Moreton asist. dr. Joshua Moreton",
    "hours": 48,
    "name": "Taikomoji socialinė psichologija"
  },
  "R8C0hmBvAxJaimyfMk4H": {
    "language": "lt",
    "hours": 50,
    "name": "Darnus vystymasis",
    "lecturers": "Prof. Dr. Asta Mikalauskienė Prof. Dr. Skaidrė Žičkienė",
    "credits": 5,
    "delivery": "online",
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=FA46B8E0DEDC015757D586148AA47FCE2441A1DF54CB9728",
    "exam": true,
    "faculty": "ŠA"
  },
  "RKYs0ycTNdUAWFYWrVUj": {
    "faculty": "IF",
    "lecturers": "Prof. Dr. Jurgita Verbickienė Doc. Dr. Grigorijus Potašenko Asist. Dr. Dovilė Troskovaitė",
    "credits": 5,
    "delivery": "online",
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=498CF33B256C1D38C1CAB96ABC123B7A3F819DA294AD3A0A",
    "hours": 48,
    "name": "Lietuvos etninių ir konfesinių bendruomenių istorija ir kultūra europiniuose kontekstuose",
    "language": "lt",
    "exam": true
  },
  "SGVxexiXsL3UwWfFBuhR": {
    "lecturers": "Lekt. Dr. Johanas Baltrimas Lekt. Dr. Johanas Baltrimas Asist. Dr. Laura Paškevičienė",
    "delivery": "face",
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=F1008F4A1626D34F0DBC82E229450870A5C439049577FE3C",
    "language": "lt",
    "faculty": "TF",
    "hours": 50,
    "exam": true,
    "credits": 5,
    "name": "Konstitucija: žmogus, teisė, valstybė"
  },
  "SW88IOI5nXotaG5RXeWu": {
    "credits": 5,
    "hours": 60,
    "name": "Retorika",
    "delivery": "online",
    "faculty": "ŠA",
    "language": "lt",
    "exam": true,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=9B4EAAB3E0D0B178AAED6EC375E380215972969A10E7C577",
    "lecturers": "Prof. Dr. Irena Smetonienė Doc. Dr. Jolanta Vaskelienė"
  },
  "TmC4jE46KaqxdRIUyFqG": {
    "faculty": "FLF",
    "credits": 5,
    "name": "Biblija Vakarų pasaulio literatūroje",
    "language": "en",
    "exam": true,
    "lecturers": "Doc. Dr. Dalia Čiočytė",
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=CF0177C77EE93FAF4AC4EF8C42534EE6B849082EBA3C5BB0",
    "hours": 58,
    "delivery": "online"
  },
  "TmLoNGalNiUn8c4Pl0Cq": {
    "delivery": "online",
    "exam": true,
    "language": "lt",
    "lecturers": "Prof. Dr. Rytis Urniežius Prof. Dr. Rytis Urniežius",
    "credits": 5,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=AF399D175CCE0856C22788B745140E64F1245C498F2295F9",
    "name": "Muzika Europos kultūroje",
    "hours": 48,
    "faculty": "ŠA"
  },
  "TvwXFcU8uKGFQy2LDwQA": {
    "delivery": "face",
    "credits": 5,
    "faculty": "FF",
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=6CE40D4F5783E024E434758D24AF941221FDBDF172FEF836",
    "name": "Astronomija: tyrinėjant laiką ir erdvę",
    "language": "lt",
    "exam": true,
    "hours": 48,
    "lecturers": "Doc. Dr. Šarūnas Mikolaitis Prof. Habil. dr. Gražina TautvaišienėAsist. Dr. Arnas Drazdauskas Vyresn. M. D. Dr. Renata MinkevičiūtėDoc. Dr. Edita Stonkutė"
  },
  "TyIjVFXQAQPbdHMBnnls": {
    "credits": 5,
    "lecturers": "Asist. Dr. Mindaugas Gilaitis Asist. Dr. Mindaugas Gilaitis",
    "delivery": "face",
    "faculty": "FILF",
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=98A202A8F0DA64CD582BEE3E75746A74DEF0CEAB8054A218",
    "language": "lt",
    "exam": true,
    "hours": 48,
    "name": "Logika"
  },
  "VHdU9ovlHcOSsJHtoRgR": {
    "exam": true,
    "faculty": "FILF",
    "credits": 5,
    "hours": 48,
    "name": "Sociologija",
    "lecturers": "Asist. Dr. Irma Budginaitė-Mačkinė Asist. Dr. Irma Budginaitė-Mačkinė",
    "delivery": "face",
    "language": "en",
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=A47AB50E8CF511485AEB7CC6C7D5FC37A0844AA1931C02D9"
  },
  "VISYxadYbIPTLGxWNk9O": {
    "lecturers": "Asist. Dr. Živilė Pabijutaitė Asist. Dr. Živilė Pabijutaitė",
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=B334D0CE53BACE035C8D4588858086E9D9DC1B646A46A0E3",
    "faculty": "FILF",
    "exam": true,
    "credits": 5,
    "hours": 24,
    "language": "lt",
    "name": "Logika",
    "delivery": "face"
  },
  "VfVyRJSqgZNtXGh8RAQA": {
    "language": "en",
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=A6F42FD89A10C51FF2751AD23F540A8B3EEEE14FE28AC489",
    "faculty": "KNF",
    "delivery": "online",
    "name": "Filosofija",
    "lecturers": "Doc. Dr. Audronė Rimkutė Doc. Dr. Audronė Rimkutė",
    "exam": true,
    "credits": 5
  },
  "WD7Wnh6ajOtRSZ76vpE9": {
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=C19E08DBB2A3D621693379556545300B8460C045F99E78EC",
    "lecturers": "Doc. Dr. Ramūnas Časas",
    "exam": true,
    "hours": 48,
    "credits": 5,
    "name": "Rinkodara",
    "language": "lt",
    "faculty": "EVAF",
    "delivery": "online"
  },
  "WzNdxm94Dh1mLcCh2yu4": {
    "language": "lt",
    "name": "Žmogaus evoliucinė psichologija",
    "lecturers": "Vyr. M. D. Dr. Antanas Kairys Asist. Dr. Dovilė Šorytė",
    "faculty": "FILF",
    "delivery": "face",
    "hours": 50,
    "credits": 5,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=F8F6F5FEB0F044EE4187F51F61E0DC3F90DDE5CC57F94A81",
    "exam": true
  },
  "YyIxrea9umW3E7IDXLH4": {
    "hours": 48,
    "name": "Logika",
    "exam": true,
    "language": "lt",
    "faculty": "FILF",
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=6BBCA89327B6829EA351CDACBB448F514DBAF94E20945E2A",
    "lecturers": "Asist. Dr. Mindaugas Gilaitis Asist. Dr. Mindaugas Gilaitis",
    "delivery": "face",
    "credits": 5
  },
  "Z6H0xDkofEfVwYZ0Q2Og": {
    "faculty": "FILF",
    "name": "Sociologija",
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=3C80824DE13562B719939A56BD3410CC493E1EEA082B2DC8",
    "lecturers": "Doc. Dr. Laimutė Žilinskienė  Regimantas Juras",
    "credits": 5,
    "delivery": "online",
    "language": "lt",
    "exam": true
  },
  "ZQATo6DHC8ZcASEDGoP5": {
    "credits": 5,
    "exam": true,
    "delivery": "online",
    "language": "en",
    "name": "Klasikinė etika ir modernioji lyderystė (Classical ethics and modern leadership)",
    "lecturers": "Asist. Dr. Renata Bikauskaitė Asist. Dr. Renata Bikauskaitė",
    "faculty": "FILF",
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=8BDB4C3F1D75BC6E85FFAA400B9FCFE8E7858C88CF1C4EA2",
    "hours": 48
  },
  "ZxxiZRMtiBj0UlTvsPy6": {
    "exam": true,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=C72CF5468D99AB5F1BAE648AC861C7453C07CABB04222104",
    "faculty": "FILF",
    "language": "lt",
    "name": "Politinė filosofija",
    "lecturers": "Prof. Dr. (HP) Vytautas Radžvilas Prof. Dr. (HP) Vytautas Radžvilas",
    "hours": 48,
    "delivery": "online",
    "credits": 5
  },
  "aH0BesN9ECVYC5jSlBW1": {
    "exam": true,
    "faculty": "VM",
    "hours": 48,
    "lecturers": "Doc. Dr. Andrius Valickas Partnerystės doc.   Eglė Daunienė",
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=6894A38EF6A13D9056163ADEFF57706F028E35AC0195D3B4",
    "language": "lt",
    "name": "Lyderystė",
    "credits": 5,
    "delivery": "blended"
  },
  "ad7A1jWUKEgf5rHwe7t9": {
    "faculty": "FF",
    "credits": 5,
    "delivery": "face",
    "name": "Fizikinis pasaulio įvaizdis",
    "hours": 48,
    "exam": true,
    "lecturers": "Prof. Habil. dr. Saulius Antanas Juršėnas J. Asist.  Dovydas Banevičius Asist. Dr. Akvilė Zabiliūtė-Karaliūnė",
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=66EFB6A90780E19A1752748182D5D7D40E01116F6564C616",
    "language": "lt"
  },
  "c8ChBpuea6o3lvT63tNG": {
    "name": "Psichinės ir fizinės sveikatos vienovė",
    "credits": 5,
    "hours": 48,
    "exam": true,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=C8336CE4CA8FA990CA4A0ED9AC64C19185B59D223C469D3E",
    "lecturers": "Doc. Dr. Ramunė Žilinskienė Doc. Dr. Rima ViliūnienėDoc. Dr. Jūratė Armonienė Doc. Dr. Ramunė Žilinskienė",
    "faculty": "MF",
    "delivery": "blended",
    "language": "lt"
  },
  "d4iqD3ppruACoI3SHVrL": {
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=ECEEC6C66BDEE6887FE4824F7D1F9E294A394ECBDB80D914",
    "exam": true,
    "language": "lt",
    "hours": 48,
    "delivery": "online",
    "faculty": "TSPMI",
    "name": "Kylantys pasaulio regionai: idėjos ir konfliktai",
    "credits": 5,
    "lecturers": "Prof. Dr. Ramūnas Vilpišauskas Asist. Dr. Ieva GiedraitytėDoc. Dr. Konstantinas Andrijauskas"
  },
  "d8kV3RtqUvN0UxORpx8E": {
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=62885355D67D67ED420DC2621751FF8A6912905E49FE2189",
    "language": "en",
    "lecturers": "Lekt. Dr. Julija Tuleikytė Lekt. Dr. Julija Tuleikytė",
    "delivery": "online",
    "credits": 5,
    "exam": true,
    "faculty": "FILF",
    "name": "Globalizacija: raida ir pasekmės",
    "hours": 48
  },
  "dBJ774Cr1O1QhZIpnLnB": {
    "hours": 48,
    "lecturers": "Doc. Dr. Andrius Petrašiūnas Doc. Dr. Andrius Petrašiūnas",
    "credits": 5,
    "name": "Biologinė evoliucija",
    "exam": true,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=FA42972A49D339C95CC17C72101E3B0416F29090CA56C321",
    "delivery": "blended",
    "faculty": "GMC",
    "language": "lt"
  },
  "eOLykB9AGHAgZvbTFrZs": {
    "exam": true,
    "delivery": "online",
    "language": "lt",
    "faculty": "FILF",
    "credits": 5,
    "lecturers": "Asist. Dr. Jonas Čiurlionis",
    "name": "Estetika",
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=828DBCAB797DD0660334BAC11EEAAD277B102EA900E50B6E"
  },
  "fFhLtPjTsXtaifPbd1Uy": {
    "delivery": "online",
    "faculty": "FILF",
    "lecturers": "Doc. Dr. Arūnas Mickevičius",
    "language": "lt",
    "name": "Etika",
    "credits": 5,
    "exam": true,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=01D01FF1B66B3DF53767720CBF0A7CAA3C635FA3862B2149"
  },
  "gDAIh032KkO7h4zBMRJ8": {
    "exam": true,
    "delivery": "online",
    "faculty": "FILF",
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=D226268B5CF90872C81A186C90382C1CA71F9E9C9281EE41",
    "hours": 48,
    "language": "lt",
    "credits": 5,
    "lecturers": "Asist. Dr. Jonas Čiurlionis Asist. Dr. Jonas Čiurlionis",
    "name": "Mokslo filosofija ir istorija"
  },
  "h051gTrRXIMhTSvRzyWs": {
    "name": "Socialinė filosofija",
    "credits": 5,
    "faculty": "FILF",
    "lecturers": "Asist. Dr. Mindaugas Stoškus Asist. Dr. Mindaugas Stoškus",
    "language": "lt",
    "hours": 48,
    "exam": true,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=829901F09EC28242C504C5AC1F621A579B8361BACA3ABDA3",
    "delivery": "online"
  },
  "h7OhkjedYNS0O3LqFAqC": {
    "lecturers": "Prof. Dr. Eglė Lastauskienė J. M. D.  Arnas Kunevičius J. M. D.  Viktorija Preitakaitė J. Asist.  Monika Šimoliūnienė",
    "faculty": "GMC",
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=3AC9AA6A82E279F55A977826B5F69C6147174CADFAF0A0C7",
    "credits": 5,
    "language": "lt",
    "hours": 48,
    "exam": true,
    "delivery": "online",
    "name": "Mikroorganizmų pasaulis"
  },
  "hG77BMwNAEhvCFMgJgCN": {
    "credits": 5,
    "exam": true,
    "faculty": "GMC",
    "name": "Augalai biologinės įvairovės kontekste",
    "hours": 48,
    "lecturers": "Asist. Dr. Radvilė Rimgailė-Voicik Lekt.  Audronė Meldžiukienė",
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=A4E162C8CF7E98552A3A98BC476B1C026F75D24E0EE54132",
    "language": "lt",
    "delivery": "face"
  },
  "hYbIuBA7HCbV061m5ERJ": {
    "name": "Tarptautinė politika",
    "lecturers": "Prof. Dr. Dovilė Jakniūnaitė Prof. Dr. Dovilė JakniūnaitėAsist. Dr. Valentinas Beržiūnas",
    "exam": true,
    "delivery": "face",
    "faculty": "TSPMI",
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=5B9FFD889B4025ED570BA10CA0C2C754CFAB3742A7021826",
    "language": "lt",
    "credits": 5
  },
  "jQ6C6HSy5mRTT1oT5k2X": {
    "hours": 48,
    "lecturers": "Asist. Dr. Mindaugas Stoškus Asist. Dr. Mindaugas Stoškus",
    "delivery": "online",
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=48C857A5837043312530E7352EE9299D87311E36175FF986",
    "exam": true,
    "credits": 5,
    "faculty": "FILF",
    "name": "Filosofija",
    "language": "lt"
  },
  "jo4Xv7fB7pOB0ROkpZxT": {
    "hours": 48,
    "exam": true,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=248B820DA9D0A710E944DDAA8A613515BA90330B723B46A4",
    "delivery": "online",
    "language": "lt",
    "credits": 5,
    "lecturers": "Prof. Dr. (HP) Zenonas Butkus Asist. Dr. Ryšard Gaidis Doc. Dr. Dalia Bukelevičiūtė",
    "name": "Lietuva ir kaimynai: istorinė patirtis ir šiuolaikinė savivoka",
    "faculty": "IF"
  },
  "klaJFuY4tvbQHOwizgWB": {
    "name": "Klimato kaita",
    "language": "en",
    "credits": 5,
    "exam": true,
    "hours": 48,
    "lecturers": "Asist. Dr. Ignas Mikalauskas Asist. Dr. Ignas Mikalauskas",
    "delivery": "online",
    "faculty": "KNF",
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=CD6DCDCD00E13FC94434528BACECBB7AC86D3979890BF663"
  },
  "lPuv1z0BnHzkOI1wIdaR": {
    "exam": true,
    "name": "Civilizacijų/Civilizacijos istorija",
    "lecturers": "Prof. Dr. (HP) Alfredas Bumblauskas Prof. Dr. (HP) Alfredas Bumblauskas Doc. Dr. Aurelijus Gieda",
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=FC5DE9C4EE8215554329116F22D735081AB1861C8BF67877",
    "credits": 5,
    "delivery": "online",
    "faculty": "IF",
    "hours": 48,
    "language": "lt"
  },
  "lgVf470llCAsb22TJA0f": {
    "hours": 48,
    "faculty": "CHGF",
    "name": "Medicininės chemijos pasaulis",
    "exam": true,
    "language": "lt",
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=BDE742911F4310E2F52A745424A6EA0383FB50AEC01A36E1",
    "delivery": "face",
    "credits": 5,
    "lecturers": "Prof. Habil. dr. Aivaras Kareiva"
  },
  "lqFhshtukNxlI2hQPuyK": {
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=3189D65BEF080F9D83DA7D1C92F9EF5E42D44374AE88F5A2",
    "credits": 5,
    "hours": 48,
    "name": "Psichologija",
    "lecturers": "Dr. Robertas Povilaitis Dr. Robertas Povilaitis",
    "delivery": "online",
    "faculty": "FILF",
    "exam": true,
    "language": "lt"
  },
  "mMvdp8o7iekQnhLpVmmT": {
    "hours": 48,
    "faculty": "KOMF",
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=936C5C76C60A3B8205D5706B4F56EDD2CA525C9F9E249964",
    "credits": 5,
    "lecturers": "Prof. Dr. Rimvydas Laužikas Doc. Dr. Vincas Grigas",
    "delivery": "face",
    "name": "Skaitmeninė kultūra",
    "language": "lt",
    "exam": true
  },
  "nW4Ag5wuTf7MGsqAIlav": {
    "hours": 54,
    "faculty": "FF",
    "exam": true,
    "credits": 5,
    "language": "en",
    "name": "Dalelių pasaulis",
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=0FF8FE919C4A887123759F9B4AF67635D6D8EBF195B8E259",
    "delivery": "face",
    "lecturers": "Doc. Dr. Thomas Gajdosik Vyresn. M. D. Dr. Andrius Juodagalvis Vyr. M. D.  Aurelijus Rinkevičius  Christoph Schaefer  Albinas Plėšnys"
  },
  "oqkesjZcuEHWMgMh34uK": {
    "delivery": "online",
    "name": "Filosofija",
    "lecturers": "Asist. Dr. Laimutė Jakavonytė",
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=BAA742C9B910B888CBB959F7F3654EE108FC4B0F9ED92E05",
    "exam": true,
    "language": "lt",
    "faculty": "FILF",
    "credits": 5,
    "hours": 48
  },
  "p9bHIB4kqyMMmUdxr41h": {
    "exam": true,
    "lecturers": "Doc. Dr. Roma Adomaitienė",
    "language": "en",
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=06F9C2DB8B2CC80980C92BD2C4F43FB32AC8FD90AA76853F",
    "delivery": "online",
    "faculty": "EVAF",
    "name": "Kokybės vadyba",
    "credits": 5,
    "hours": 48
  },
  "pMr2YefxS0ShxKu7h9rL": {
    "credits": 5,
    "language": "en",
    "hours": 54,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=574863E38A9D4A723051CEAEF23CDDAACFF9A978E28A7FD0",
    "exam": true,
    "delivery": "online",
    "lecturers": "Prof. Habil. dr. Aivaras Kareiva Lekt. Dr. Skirmantė Tutlienė",
    "name": "Žmogus, buitis ir chemija",
    "faculty": "CHGF"
  },
  "paQ48Hvq46Uuk6VUfAp5": {
    "lecturers": "Asist. Dr. Goda GegieckaitėAsist. Dr. Goda Gegieckaitė",
    "hours": 48,
    "delivery": "online",
    "name": "Psichologija",
    "language": "lt",
    "credits": 5,
    "faculty": "FILF",
    "exam": true,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=5939F55C531535AD7AD2503C2C2FCDB535BA92528157A813"
  },
  "r1uREVqYdsTMdojq1j70": {
    "hours": 48,
    "exam": true,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=EA8C0AF79063168B3D7DCE04BC5B328EAD4A94E7961F1674",
    "name": "Bendravimo psichologija",
    "faculty": "FILF",
    "delivery": "online",
    "language": "en",
    "credits": 5,
    "lecturers": "asist. dr. Joshua Moreton"
  },
  "tNmmvuCzwF60cZiQEvwd": {
    "delivery": "online",
    "language": "lt",
    "lecturers": "Prof. Habil. dr. Aivaras Kareiva",
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=2B7A1549B7E8A167EB5146081A9F70BF637F6122B181FD41",
    "credits": 5,
    "hours": 48,
    "exam": true,
    "name": "Cheminių elementų pasaulis",
    "faculty": "CHGF"
  },
  "uXPgZrS1pNOPf17l4ytv": {
    "language": "lt",
    "lecturers": "Prof. Dr. Virginija Jurėnienė Prof. Dr. Virginija Jurėnienė",
    "name": "Lyginamoji civilizacijų istorija",
    "credits": 5,
    "delivery": "blended",
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=7B8DC2CE43A7B70812AFEFA3B9A4EDFC643BEFB6E3B1FF9A",
    "exam": true,
    "faculty": "KNF",
    "hours": 48
  },
  "utkJFL7twlzACX6tZCUi": {
    "exam": true,
    "credits": 5,
    "language": "lt",
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=E56C01A2CF8D72F9721E910E526DB113EBDFA1FE1F84B3D2",
    "faculty": "FILF",
    "delivery": "online",
    "name": "Asmenybė ir jos tapsmas",
    "lecturers": "Dr. Giedrė Ambrulaitienė  Karolina Petraškaitė",
    "hours": 48
  },
  "vHcS9SajaSloeMtKA2I1": {
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=75348A767E84F59C7B8BB34DC0A53D4D26B921E3724403A6",
    "exam": true,
    "hours": 48,
    "credits": 5,
    "delivery": "online",
    "name": "Psichologija",
    "faculty": "FILF",
    "language": "lt",
    "lecturers": "Dr. Robertas Povilaitis Dr. Robertas Povilaitis"
  },
  "wehw3JtkbWYH5VsjNeC0": {
    "delivery": "blended",
    "lecturers": "Prof. Dr. Virginija Jurėnienė Prof. Dr. Virginija Jurėnienė",
    "faculty": "KNF",
    "name": "Lyčių istorija ir teorija",
    "exam": true,
    "credits": 5,
    "hours": 48,
    "language": "lt",
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=B83BF9415E8B81B6E5B3BAACEC70CBDE73E4F6F0C613FA85"
  },
  "zPIJEPXd0SXVu1x5LBda": {
    "exam": true,
    "name": "Pasaulio religijos",
    "hours": 48,
    "faculty": "FILF",
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=2862FB5DA39423F2B1000F8FD24998F1FF1116CA0D6B57AA",
    "delivery": "online",
    "lecturers": "Doc. Dr. Kęstutis Dubnikas Doc. Dr. Kęstutis Dubnikas",
    "credits": 5,
    "language": "lt"
  },
  "znV3TB2wVncgi0amHomR": {
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=C0F32AEA3CFC56366A4048D25DFA58D0DDD16979BCC76687",
    "lecturers": "Prof. Dr. Virginija Jurėnienė  Ugnė Pavlovaitė",
    "delivery": "blended",
    "faculty": "KNF",
    "credits": 5,
    "name": "Šiuolaikinis menas",
    "language": "lt",
    "exam": true,
    "hours": 50
  },
  "znjKZVipNvEakXzDbCZW": {
    "faculty": "FILF",
    "language": "lt",
    "name": "Sociologija",
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=C712DE1596AFB43E75CA796758026960878306DEF7BD6D89",
    "lecturers": "Dr. Aušra Širvinskienė Dr. Aušra Širvinskienė",
    "credits": 5,
    "exam": true,
    "delivery": "face",
    "hours": 48
  }
}