import { Language } from "./../types";

export function getLanguage(language: Language) {
  if (language === Language.Lithuanian) {
    return "lietuvių";
  }
  if (language === Language.English) {
    return "Anglų";
  }
  if (language === Language.Russian) {
    return "Rusų";
  }
}
