export const subjects_en = {
  "tNmmvuCzwF60cZiQEvwd": {
    "name": "World of Chemical Elements",
    "faculty": "CHGF",
    "credits": 5,
    "delivery": "online",
    "lecturers": "Prof. Habil. dr. Aivaras Kareiva",
    "language": "lt",
    "exam": false,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=2B7A1549B7E8A167EB5146081A9F70BF637F6122B181FD41"
  },
  "lgVf470llCAsb22TJA0f": {
    "name": "The World of Medical Chemistry",
    "faculty": "CHGF",
    "credits": 5,
    "delivery": "live",
    "lecturers": "Prof. Habil. dr. Aivaras Kareiva",
    "language": "lt",
    "exam": false,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=BDE742911F4310E2F52A745424A6EA0383FB50AEC01A36E1"
  },
  "pMr2YefxS0ShxKu7h9rL": {
    "name": "The Chemistry of Human Nature and at home",
    "faculty": "CHGF",
    "credits": 5,
    "delivery": "online",
    "lecturers": "Prof. Habil. dr. Aivaras Kareiva Lekt. Dr. Skirmantė Tutlienė",
    "language": "en",
    "exam": false,
    "hours": 54,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=574863E38A9D4A723051CEAEF23CDDAACFF9A978E28A7FD0"
  },
  "9xBobzqje9bqpuTKLUs6": {
    "name": "Quality Management",
    "faculty": "EVAF",
    "credits": 5,
    "delivery": "online",
    "lecturers": "Doc. Dr. Roma Adomaitienė",
    "language": "lt",
    "exam": false,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=7478B46F8A397C61EF833DE1B896208813EFBCC4B68744BC"
  },
  "p9bHIB4kqyMMmUdxr41h": {
    "name": "Quality Management",
    "faculty": "EVAF",
    "credits": 5,
    "delivery": "online",
    "lecturers": "Doc. Dr. Roma Adomaitienė",
    "language": "en",
    "exam": false,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=06F9C2DB8B2CC80980C92BD2C4F43FB32AC8FD90AA76853F"
  },
  "NIqYhLAWiNz6mSCFtS8r": {
    "name": "Organizations Management",
    "faculty": "EVAF",
    "credits": 5,
    "delivery": "online",
    "lecturers": "Asist. Dr. Darius Ruželė",
    "language": "lt",
    "exam": false,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=7250DC9002DFCB9B8B9092BC833CD896F47FB5CF893AA0C7"
  },
  "WD7Wnh6ajOtRSZ76vpE9": {
    "name": "Marketing",
    "faculty": "EVAF",
    "credits": 5,
    "delivery": "online",
    "lecturers": "Doc. Dr. Ramūnas Časas",
    "language": "lt",
    "exam": false,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=C19E08DBB2A3D621693379556545300B8460C045F99E78EC"
  },
  "TmC4jE46KaqxdRIUyFqG": {
    "name": "The Bible in Western Literature",
    "faculty": "FLF",
    "credits": 5,
    "delivery": "online",
    "lecturers": "Doc. Dr. Dalia Čiočytė",
    "language": "en",
    "exam": false,
    "hours": 58,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=CF0177C77EE93FAF4AC4EF8C42534EE6B849082EBA3C5BB0"
  },
  "4JC7mpOaRKQzpuz3uUjh": {
    "name": "The Culture of Laughter",
    "faculty": "FLF",
    "credits": 5,
    "delivery": "live",
    "lecturers": "Doc. Dr. Inga Vidugirytė-Pakerienė",
    "language": "lt",
    "exam": false,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=576C9FCC5E119A23F4F39A566E616DCBF5538B30CE79E9B7"
  },
  "utkJFL7twlzACX6tZCUi": {
    "name": "Personality and it's Development",
    "faculty": "FILF",
    "credits": 5,
    "delivery": "online",
    "lecturers": "Dr. Giedrė Ambrulaitienė  Karolina Petraškaitė",
    "language": "lt",
    "exam": false,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=E56C01A2CF8D72F9721E910E526DB113EBDFA1FE1F84B3D2"
  },
  "1rSh7yKbjpEl97yME7R8": {
    "name": "Personality and it's Development",
    "faculty": "FILF",
    "credits": 5,
    "delivery": "online",
    "lecturers": "dr. Greta Kaluževičiūtė dr. Greta Kaluževičiūtė",
    "language": "lt",
    "exam": false,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=6D4BE63609AD5009EF4D7B829ADA1C365B1D60BA4C4FA7E7"
  },
  "OoVRTx1fEw6Fg0BIhInB": {
    "name": "Communication Psychology",
    "faculty": "FILF",
    "credits": 5,
    "delivery": "online",
    "lecturers": "Asist. Dr. Greta Kaluževičiūtė  Austėja Agnietė Čepulienė  Gitana Naudužienė",
    "language": "lt",
    "exam": false,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=DC7E2F72FDA82BA2A35DA6DA9CB0697D48732B2EBB32B86E"
  },
  "r1uREVqYdsTMdojq1j70": {
    "name": "Communication Psychology",
    "faculty": "FILF",
    "credits": 5,
    "delivery": "online",
    "lecturers": "asist. dr. Joshua Moreton",
    "language": "en",
    "exam": false,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=EA8C0AF79063168B3D7DCE04BC5B328EAD4A94E7961F1674"
  },
  "eOLykB9AGHAgZvbTFrZs": {
    "name": "Aesthetics",
    "faculty": "FILF",
    "credits": 5,
    "delivery": "online",
    "lecturers": "Asist. Dr. Jonas Čiurlionis",
    "language": "lt",
    "exam": false,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=828DBCAB797DD0660334BAC11EEAAD277B102EA900E50B6E"
  },
  "fFhLtPjTsXtaifPbd1Uy": {
    "name": "Ethics",
    "faculty": "FILF",
    "credits": 5,
    "delivery": "online",
    "lecturers": "Doc. Dr. Arūnas Mickevičius",
    "language": "lt",
    "exam": false,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=01D01FF1B66B3DF53767720CBF0A7CAA3C635FA3862B2149"
  },
  "oqkesjZcuEHWMgMh34uK": {
    "name": "Philosophy",
    "faculty": "FILF",
    "credits": 5,
    "delivery": "online",
    "lecturers": "Asist. Dr. Laimutė Jakavonytė",
    "language": "lt",
    "exam": false,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=BAA742C9B910B888CBB959F7F3654EE108FC4B0F9ED92E05"
  },
  "jQ6C6HSy5mRTT1oT5k2X": {
    "name": "Philosophy",
    "faculty": "FILF",
    "credits": 5,
    "delivery": "online",
    "lecturers": "Asist. Dr. Mindaugas Stoškus Asist. Dr. Mindaugas Stoškus",
    "language": "lt",
    "exam": false,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=48C857A5837043312530E7352EE9299D87311E36175FF986"
  },
  "KlJhj65ELwM2zb0bnWHf": {
    "name": "Philosophy",
    "faculty": "FILF",
    "credits": 5,
    "delivery": "online",
    "lecturers": "Asist. Dr. Laimutė Jakavonytė Asist. Dr. Laimutė Jakavonytė",
    "language": "en",
    "exam": false,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=39C5C3A62C0064B517ED4C5CE31D42D6A94320FEB34396CC"
  },
  "d8kV3RtqUvN0UxORpx8E": {
    "name": "Globalisation: Development and Consequences",
    "faculty": "FILF",
    "credits": 5,
    "delivery": "online",
    "lecturers": "Lekt. Dr. Julija Tuleikytė Lekt. Dr. Julija Tuleikytė",
    "language": "en",
    "exam": false,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=62885355D67D67ED420DC2621751FF8A6912905E49FE2189"
  },
  "Eke3wXBHQ1wk1Gstfccp": {
    "name": "Globalisation: Development and Consequences",
    "faculty": "FILF",
    "credits": 5,
    "delivery": "online",
    "lecturers": "Lekt. Dr. Julija Tuleikytė Lekt. Dr. Julija Tuleikytė",
    "language": "lt",
    "exam": false,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=9D28F2544C224CF01B6DADCE76D7F16768C274754B10E53A"
  },
  "4uRINedUNyBVDEd2QcJQ": {
    "name": "Classical Ethics and Modern Leadership",
    "faculty": "FILF",
    "credits": 5,
    "delivery": "online",
    "lecturers": "Asist. Dr. Renata Bikauskaitė Asist. Dr. Renata Bikauskaitė",
    "language": "lt",
    "exam": false,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=99A84C7D8EFAF580F12374531011A0682BFCA54366B7066A"
  },
  "ZQATo6DHC8ZcASEDGoP5": {
    "name": "Classical Ethics and Modern Leadership (Classical ethics and modern leadership)",
    "faculty": "FILF",
    "credits": 5,
    "delivery": "online",
    "lecturers": "Asist. Dr. Renata Bikauskaitė Asist. Dr. Renata Bikauskaitė",
    "language": "en",
    "exam": false,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=8BDB4C3F1D75BC6E85FFAA400B9FCFE8E7858C88CF1C4EA2"
  },
  "VISYxadYbIPTLGxWNk9O": {
    "name": "Logic",
    "faculty": "FILF",
    "credits": 5,
    "delivery": "live",
    "lecturers": "Asist. Dr. Živilė Pabijutaitė Asist. Dr. Živilė Pabijutaitė",
    "language": "lt",
    "exam": false,
    "hours": 24,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=B334D0CE53BACE035C8D4588858086E9D9DC1B646A46A0E3"
  },
  "YyIxrea9umW3E7IDXLH4": {
    "name": "Logic",
    "faculty": "FILF",
    "credits": 5,
    "delivery": "live",
    "lecturers": "Asist. Dr. Mindaugas Gilaitis Asist. Dr. Mindaugas Gilaitis",
    "language": "lt",
    "exam": false,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=6BBCA89327B6829EA351CDACBB448F514DBAF94E20945E2A"
  },
  "TyIjVFXQAQPbdHMBnnls": {
    "name": "Logic",
    "faculty": "FILF",
    "credits": 5,
    "delivery": "live",
    "lecturers": "Asist. Dr. Mindaugas Gilaitis Asist. Dr. Mindaugas Gilaitis",
    "language": "lt",
    "exam": false,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=98A202A8F0DA64CD582BEE3E75746A74DEF0CEAB8054A218"
  },
  "gDAIh032KkO7h4zBMRJ8": {
    "name": "History and Philosophy of Science",
    "faculty": "FILF",
    "credits": 5,
    "delivery": "online",
    "lecturers": "Asist. Dr. Jonas Čiurlionis Asist. Dr. Jonas Čiurlionis",
    "language": "lt",
    "exam": false,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=D226268B5CF90872C81A186C90382C1CA71F9E9C9281EE41"
  },
  "zPIJEPXd0SXVu1x5LBda": {
    "name": "World Religions",
    "faculty": "FILF",
    "credits": 5,
    "delivery": "online",
    "lecturers": "Doc. Dr. Kęstutis Dubnikas Doc. Dr. Kęstutis Dubnikas",
    "language": "lt",
    "exam": false,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=2862FB5DA39423F2B1000F8FD24998F1FF1116CA0D6B57AA"
  },
  "ZxxiZRMtiBj0UlTvsPy6": {
    "name": "Political Philosophy",
    "faculty": "FILF",
    "credits": 5,
    "delivery": "online",
    "lecturers": "Prof. Dr. (HP) Vytautas Radžvilas Prof. Dr. (HP) Vytautas Radžvilas",
    "language": "lt",
    "exam": false,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=C72CF5468D99AB5F1BAE648AC861C7453C07CABB04222104"
  },
  "vHcS9SajaSloeMtKA2I1": {
    "name": "Psychology",
    "faculty": "FILF",
    "credits": 5,
    "delivery": "online",
    "lecturers": "Dr. Robertas Povilaitis Dr. Robertas Povilaitis",
    "language": "lt",
    "exam": false,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=75348A767E84F59C7B8BB34DC0A53D4D26B921E3724403A6"
  },
  "Cw2RQ9Ut8T9rT3X1fMhU": {
    "name": "Psychology",
    "faculty": "FILF",
    "credits": 5,
    "delivery": "online",
    "lecturers": "Doc. Dr. Irena Žukauskaitė Dr. Robertas Povilaitis",
    "language": "lt",
    "exam": false,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=41B6E922BC43DF7B1334A0866D02EE73ED51171E32328CF6"
  },
  "paQ48Hvq46Uuk6VUfAp5": {
    "name": "Psychology",
    "faculty": "FILF",
    "credits": 5,
    "delivery": "online",
    "lecturers": "Asist. Dr. Goda GegieckaitėAsist. Dr. Goda Gegieckaitė",
    "language": "lt",
    "exam": false,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=5939F55C531535AD7AD2503C2C2FCDB535BA92528157A813"
  },
  "lqFhshtukNxlI2hQPuyK": {
    "name": "Psychology",
    "faculty": "FILF",
    "credits": 5,
    "delivery": "online",
    "lecturers": "Dr. Robertas Povilaitis Dr. Robertas Povilaitis",
    "language": "lt",
    "exam": false,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=3189D65BEF080F9D83DA7D1C92F9EF5E42D44374AE88F5A2"
  },
  "0X4ar1ktFAmWvRBErtcN": {
    "name": "Eastern Philosophy and Ethics",
    "faculty": "FILF",
    "credits": 5,
    "delivery": "live",
    "lecturers": "Asist. Dr. Vladimir Korobov Asist. Dr. Vladimir Korobov",
    "language": "lt",
    "exam": false,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=FA5252C555D73F2A617865E024D749FF482B95EF570C5A67"
  },
  "h051gTrRXIMhTSvRzyWs": {
    "name": "Social Philosophy",
    "faculty": "FILF",
    "credits": 5,
    "delivery": "online",
    "lecturers": "Asist. Dr. Mindaugas Stoškus Asist. Dr. Mindaugas Stoškus",
    "language": "lt",
    "exam": false,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=829901F09EC28242C504C5AC1F621A579B8361BACA3ABDA3"
  },
  "Z6H0xDkofEfVwYZ0Q2Og": {
    "name": "Sociology",
    "faculty": "FILF",
    "credits": 5,
    "delivery": "online",
    "lecturers": "Doc. Dr. Laimutė Žilinskienė  Regimantas Juras",
    "language": "lt",
    "exam": false,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=3C80824DE13562B719939A56BD3410CC493E1EEA082B2DC8"
  },
  "znjKZVipNvEakXzDbCZW": {
    "name": "Sociology",
    "faculty": "FILF",
    "credits": 5,
    "delivery": "live",
    "lecturers": "Dr. Aušra Širvinskienė Dr. Aušra Širvinskienė",
    "language": "lt",
    "exam": false,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=C712DE1596AFB43E75CA796758026960878306DEF7BD6D89"
  },
  "VHdU9ovlHcOSsJHtoRgR": {
    "name": "Sociology",
    "faculty": "FILF",
    "credits": 5,
    "delivery": "live",
    "lecturers": "Asist. Dr. Irma Budginaitė-Mačkinė Asist. Dr. Irma Budginaitė-Mačkinė",
    "language": "en",
    "exam": false,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=A47AB50E8CF511485AEB7CC6C7D5FC37A0844AA1931C02D9"
  },
  "Ctoa3IYAEjHz87Z1klKW": {
    "name": "Sociology",
    "faculty": "FILF",
    "credits": 5,
    "delivery": "live",
    "lecturers": "Dr. Aušra Širvinskienė  Jūratė Litvinaitė  Laura Daukšaitė  Rima Žilinskaitė",
    "language": "lt",
    "exam": false,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=9E3FAF9CE695983BFC6FA3DB8DF08EBC6D192F436F1A8BE4"
  },
  "JQmLcq6Lbtg0fVyBhqKK": {
    "name": "Applied Social Psychology",
    "faculty": "FILF",
    "credits": 5,
    "delivery": "live",
    "lecturers": "Asist. Dr. Olga Zamalijeva Dr. Tomas Maceina",
    "language": "lt",
    "exam": false,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=9D2FD7A760CB845945780A4E46BF4AC32DAB88E2A2CA56DD"
  },
  "R23GxscbFk7DWdinTrIs": {
    "name": "Applied Social Psychology",
    "faculty": "FILF",
    "credits": 5,
    "delivery": "online",
    "lecturers": "asist. dr. Joshua Moreton asist. dr. Joshua Moreton",
    "language": "en",
    "exam": false,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=2D676BD00AC7B5775E215FE410618EA167EDB488DC4771DE"
  },
  "WzNdxm94Dh1mLcCh2yu4": {
    "name": "Human Evolutionary Psychology",
    "faculty": "FILF",
    "credits": 5,
    "delivery": "live",
    "lecturers": "Vyr. M. D. Dr. Antanas Kairys Asist. Dr. Dovilė Šorytė",
    "language": "lt",
    "exam": false,
    "hours": 50,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=F8F6F5FEB0F044EE4187F51F61E0DC3F90DDE5CC57F94A81"
  },
  "TvwXFcU8uKGFQy2LDwQA": {
    "name": "Astronomy: Exploring Time and Space",
    "faculty": "FF",
    "credits": 5,
    "delivery": "live",
    "lecturers": "Doc. Dr. Šarūnas Mikolaitis Prof. Habil. dr. Gražina TautvaišienėAsist. Dr. Arnas Drazdauskas Vyresn. M. D. Dr. Renata MinkevičiūtėDoc. Dr. Edita Stonkutė",
    "language": "lt",
    "exam": false,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=6CE40D4F5783E024E434758D24AF941221FDBDF172FEF836"
  },
  "nW4Ag5wuTf7MGsqAIlav": {
    "name": "World of Particles",
    "faculty": "FF",
    "credits": 5,
    "delivery": "live",
    "lecturers": "Doc. Dr. Thomas Gajdosik Vyresn. M. D. Dr. Andrius Juodagalvis Vyr. M. D.  Aurelijus Rinkevičius  Christoph Schaefer  Albinas Plėšnys",
    "language": "en",
    "exam": false,
    "hours": 54,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=0FF8FE919C4A887123759F9B4AF67635D6D8EBF195B8E259"
  },
  "ad7A1jWUKEgf5rHwe7t9": {
    "name": "Physical Representation of the World",
    "faculty": "FF",
    "credits": 5,
    "delivery": "live",
    "lecturers": "Prof. Habil. dr. Saulius Antanas Juršėnas J. Asist.  Dovydas Banevičius Asist. Dr. Akvilė Zabiliūtė-Karaliūnė",
    "language": "lt",
    "exam": false,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=66EFB6A90780E19A1752748182D5D7D40E01116F6564C616"
  },
  "hG77BMwNAEhvCFMgJgCN": {
    "name": "Plants in Biodiversity Context",
    "faculty": "GMC",
    "credits": 5,
    "delivery": "live",
    "lecturers": "Asist. Dr. Radvilė Rimgailė-Voicik Lekt.  Audronė Meldžiukienė",
    "language": "lt",
    "exam": false,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=A4E162C8CF7E98552A3A98BC476B1C026F75D24E0EE54132"
  },
  "dBJ774Cr1O1QhZIpnLnB": {
    "name": "Biological Evoliution",
    "faculty": "GMC",
    "credits": 5,
    "delivery": "blended",
    "lecturers": "Doc. Dr. Andrius Petrašiūnas Doc. Dr. Andrius Petrašiūnas",
    "language": "lt",
    "exam": false,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=FA42972A49D339C95CC17C72101E3B0416F29090CA56C321"
  },
  "FUxjUlKM3vrxJcbYjnpd": {
    "name": "Basics of Animal Behaviour",
    "faculty": "GMC",
    "credits": 5,
    "delivery": "online",
    "lecturers": "Asist. Dr. Irena Nedveckytė Asist. Dr. Irena Nedveckytė",
    "language": "lt",
    "exam": false,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=DE4B0BD92C14C68B7FBDDEC7DD9D7EF687BB7A0C3E8A40A8"
  },
  "h7OhkjedYNS0O3LqFAqC": {
    "name": "The World of Microorganisms",
    "faculty": "GMC",
    "credits": 5,
    "delivery": "online",
    "lecturers": "Prof. Dr. Eglė Lastauskienė J. M. D.  Arnas Kunevičius J. M. D.  Viktorija Preitakaitė J. Asist.  Monika Šimoliūnienė",
    "language": "lt",
    "exam": false,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=3AC9AA6A82E279F55A977826B5F69C6147174CADFAF0A0C7"
  },
  "lPuv1z0BnHzkOI1wIdaR": {
    "name": "History of Civilisation/Civilisations",
    "faculty": "IF",
    "credits": 5,
    "delivery": "online",
    "lecturers": "Prof. Dr. (HP) Alfredas Bumblauskas Prof. Dr. (HP) Alfredas Bumblauskas Doc. Dr. Aurelijus Gieda",
    "language": "lt",
    "exam": false,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=FC5DE9C4EE8215554329116F22D735081AB1861C8BF67877"
  },
  "MD4y9XE0alDH1hzXaFSG": {
    "name": "History of Europe: Epochs and Regions",
    "faculty": "IF",
    "credits": 5,
    "delivery": "online",
    "lecturers": "Asist. Dr. Nerijus Babinskas Asist. Dr. Nerijus Babinskas",
    "language": "lt",
    "exam": false,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=6B4A6CDE009162A938F3A508B752BE7D3C78F5322F796E64"
  },
  "jo4Xv7fB7pOB0ROkpZxT": {
    "name": "Lithuania and its Neighbours: Historical Perspective and Contemporary Understanding",
    "faculty": "IF",
    "credits": 5,
    "delivery": "online",
    "lecturers": "Prof. Dr. (HP) Zenonas Butkus Asist. Dr. Ryšard Gaidis Doc. Dr. Dalia Bukelevičiūtė",
    "language": "lt",
    "exam": false,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=248B820DA9D0A710E944DDAA8A613515BA90330B723B46A4"
  },
  "RKYs0ycTNdUAWFYWrVUj": {
    "name": "Lithuanian Ethnic and Confessional Minorities: History and Culture in the European Contexts",
    "faculty": "IF",
    "credits": 5,
    "delivery": "online",
    "lecturers": "Prof. Dr. Jurgita Verbickienė Doc. Dr. Grigorijus Potašenko Asist. Dr. Dovilė Troskovaitė",
    "language": "lt",
    "exam": false,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=498CF33B256C1D38C1CAB96ABC123B7A3F819DA294AD3A0A"
  },
  "FGrUCxwWx68pRQPPtRQI": {
    "name": "Heritage: from Globality to Local Culture",
    "faculty": "IF",
    "credits": 5,
    "delivery": "online",
    "lecturers": "Doc. Dr. Salvijus Kulevičius Prof. Dr. Irena Vaišvilaitė",
    "language": "lt",
    "exam": false,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=61297DDE1DDE03EAA4E75A678B9F6444C3C4AC763D484334"
  },
  "NvuBq3K2gresRXhB9ZgL": {
    "name": "Sustainable Development",
    "faculty": "KNF",
    "credits": 5,
    "delivery": "online",
    "lecturers": "Prof. Dr. Asta Mikalauskienė Prof. Dr. Asta Mikalauskienė",
    "language": "lt",
    "exam": false,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=6CCD8162E9CB849F7F11705039E76CE9B951A23267C16479"
  },
  "A1Eh4vmxho5Ce9Vws8qX": {
    "name": "Language of Negotiations",
    "faculty": "KNF",
    "credits": 5,
    "delivery": "online",
    "lecturers": "Doc. Dr. Skirmantė Biržietienė Doc. Dr. Eglė Gabrėnaitė",
    "language": "lt",
    "exam": false,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=C2373106E83947AF1873B50395B30545D47296A912F4E8A9"
  },
  "VfVyRJSqgZNtXGh8RAQA": {
    "name": "Philosophy",
    "faculty": "KNF",
    "credits": 5,
    "delivery": "online",
    "lecturers": "Doc. Dr. Audronė Rimkutė Doc. Dr. Audronė Rimkutė",
    "language": "en",
    "exam": false,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=A6F42FD89A10C51FF2751AD23F540A8B3EEEE14FE28AC489"
  },
  "klaJFuY4tvbQHOwizgWB": {
    "name": "Climate Change",
    "faculty": "KNF",
    "credits": 5,
    "delivery": "online",
    "lecturers": "Asist. Dr. Ignas Mikalauskas Asist. Dr. Ignas Mikalauskas",
    "language": "en",
    "exam": false,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=CD6DCDCD00E13FC94434528BACECBB7AC86D3979890BF663"
  },
  "1VxJ46m7HQi2Ok0ewLSl": {
    "name": "Culture and Consumption",
    "faculty": "KNF",
    "credits": 5,
    "delivery": "online",
    "lecturers": "Doc. Dr. Audronė Rimkutė Doc. Dr. Audronė Rimkutė",
    "language": "lt",
    "exam": false,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=392519892AED70B989BEECE7328FF442B4061B650C3D8FF4"
  },
  "wehw3JtkbWYH5VsjNeC0": {
    "name": "Gender History and Theory",
    "faculty": "KNF",
    "credits": 5,
    "delivery": "blended",
    "lecturers": "Prof. Dr. Virginija Jurėnienė Prof. Dr. Virginija Jurėnienė",
    "language": "lt",
    "exam": false,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=B83BF9415E8B81B6E5B3BAACEC70CBDE73E4F6F0C613FA85"
  },
  "uXPgZrS1pNOPf17l4ytv": {
    "name": "Comparative History of Civilisations",
    "faculty": "KNF",
    "credits": 5,
    "delivery": "blended",
    "lecturers": "Prof. Dr. Virginija Jurėnienė Prof. Dr. Virginija Jurėnienė",
    "language": "lt",
    "exam": false,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=7B8DC2CE43A7B70812AFEFA3B9A4EDFC643BEFB6E3B1FF9A"
  },
  "IMX8bmhOfazPhuppoRmD": {
    "name": "Cognition, Thinking and Language",
    "faculty": "KNF",
    "credits": 5,
    "delivery": "blended",
    "lecturers": "Doc. Dr. Jurgita Kerevičienė Doc. Dr. Jurgita Kerevičienė",
    "language": "en",
    "exam": false,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=A99EBA5F766770184786152CF55CA4F5E8F692B8A7976FCF"
  },
  "znV3TB2wVncgi0amHomR": {
    "name": "Contemporary Art",
    "faculty": "KNF",
    "credits": 5,
    "delivery": "blended",
    "lecturers": "Prof. Dr. Virginija Jurėnienė  Ugnė Pavlovaitė",
    "language": "lt",
    "exam": false,
    "hours": 50,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=C0F32AEA3CFC56366A4048D25DFA58D0DDD16979BCC76687"
  },
  "J3LpEjwxM6VtxezNDJ4P": {
    "name": "Gender Studies",
    "faculty": "KOMF",
    "credits": 5,
    "delivery": "live",
    "lecturers": "Doc. Dr. Lijana Stundžė Doc. Dr. Ilona MichailovičDoc. Dr. Aurelija NovelskaitėAsist. Dr. Ernesta Platūkytė",
    "language": "lt",
    "exam": false,
    "hours": 50,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=5D67AD7C77FE6F02DDC30A3A0A15F9D2A7797DDD184406B7"
  },
  "5OjVJvnRqmyoCFSJDNSl": {
    "name": "Equal Opportunities and Non-Discrimination",
    "faculty": "KOMF",
    "credits": 5,
    "delivery": "live",
    "lecturers": "Doc. Dr. Ilona Michailovič Doc. Dr. Lijana Stundžė Asist. Dr. Reda Adomaitienė",
    "language": "lt",
    "exam": false,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=2EC064E538A11AD929234F12D178C7FECAC1137DC429B937"
  },
  "mMvdp8o7iekQnhLpVmmT": {
    "name": "Digital Culture",
    "faculty": "KOMF",
    "credits": 5,
    "delivery": "live",
    "lecturers": "Prof. Dr. Rimvydas Laužikas Doc. Dr. Vincas Grigas",
    "language": "lt",
    "exam": false,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=936C5C76C60A3B8205D5706B4F56EDD2CA525C9F9E249964"
  },
  "c8ChBpuea6o3lvT63tNG": {
    "name": "Mental and Physical Health",
    "faculty": "MF",
    "credits": 5,
    "delivery": "blended",
    "lecturers": "Doc. Dr. Ramunė Žilinskienė Doc. Dr. Rima ViliūnienėDoc. Dr. Jūratė Armonienė Doc. Dr. Ramunė Žilinskienė",
    "language": "lt",
    "exam": false,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=C8336CE4CA8FA990CA4A0ED9AC64C19185B59D223C469D3E"
  },
  "LiY0yIxnEzSJf6ElDD5R": {
    "name": "Psychoactive Substances: Effects, Addiction, Prevention",
    "faculty": "MF",
    "credits": 5,
    "delivery": "online",
    "lecturers": "Doc. Dr. Robertas Badaras Asist. Dr. Ingrida LisauskienėProf. Dr. (HP) Osvaldas Rukšėnas Prof. Dr. Alvydas Navickas Prof. Dr. Laimutė BulotaitėPartnerystės doc.   Taurimas Valys",
    "language": "lt",
    "exam": false,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=BA926F549C576DA9086D6557C0002528C3C19C41F4897B67"
  },
  "Mb39CNkaZFsgNsBjYtEj": {
    "name": "Sustainable Development",
    "faculty": "ŠA",
    "credits": 5,
    "delivery": "online",
    "lecturers": "Prof. Dr. Asta Mikalauskienė Prof. Dr. Skaidrė Žičkienė",
    "language": "en",
    "exam": false,
    "hours": 50,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=9E12F9E7243543C2B18DE79566790CF30AC7D77D5E18BE2A"
  },
  "R8C0hmBvAxJaimyfMk4H": {
    "name": "Sustainable Development",
    "faculty": "ŠA",
    "credits": 5,
    "delivery": "online",
    "lecturers": "Prof. Dr. Asta Mikalauskienė Prof. Dr. Skaidrė Žičkienė",
    "language": "lt",
    "exam": false,
    "hours": 50,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=FA46B8E0DEDC015757D586148AA47FCE2441A1DF54CB9728"
  },
  "TmLoNGalNiUn8c4Pl0Cq": {
    "name": "Music in European Culture",
    "faculty": "ŠA",
    "credits": 5,
    "delivery": "online",
    "lecturers": "Prof. Dr. Rytis Urniežius Prof. Dr. Rytis Urniežius",
    "language": "lt",
    "exam": false,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=AF399D175CCE0856C22788B745140E64F1245C498F2295F9"
  },
  "QLelzZcbZc42HEYQwQU6": {
    "name": "Rhetoric",
    "faculty": "ŠA",
    "credits": 5,
    "delivery": "online",
    "lecturers": "Prof. Dr. Irena Smetonienė Doc. Dr. Jolanta Vaskelienė",
    "language": "lt",
    "exam": false,
    "hours": 60,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=FEDC8D39ED87AB4D4CF2C1DFD5149019082AF37E32F02D3D"
  },
  "SW88IOI5nXotaG5RXeWu": {
    "name": "Rhetoric",
    "faculty": "ŠA",
    "credits": 5,
    "delivery": "online",
    "lecturers": "Prof. Dr. Irena Smetonienė Doc. Dr. Jolanta Vaskelienė",
    "language": "lt",
    "exam": false,
    "hours": 60,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=9B4EAAB3E0D0B178AAED6EC375E380215972969A10E7C577"
  },
  "Q1TMmggslm3vMDpHRPBx": {
    "name": "European Union Studies: The Basics",
    "faculty": "TSPMI",
    "credits": 5,
    "delivery": "online",
    "lecturers": "Prof. Dr. (HP) Gediminas Vitkus Prof. Dr. (HP) Gediminas Vitkus",
    "language": "lt",
    "exam": false,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=AFC0B7DDDA6AE38E11E5877B661BEDFE0415B5253746944C"
  },
  "Px2eIzJMgV6yz9PnR7SP": {
    "name": "Islam: from Traditional to Radical",
    "faculty": "TSPMI",
    "credits": 5,
    "delivery": "online",
    "lecturers": "Asist. Dr. Ieva Koreivaitė Asist. Dr. Ieva Koreivaitė",
    "language": "lt",
    "exam": false,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=6D10D411AB3D2DF689C91064921C2C47264F389D9EF4E9F9"
  },
  "AskI9cCme9ZchT7XUeaD": {
    "name": "War Studies",
    "faculty": "TSPMI",
    "credits": 5,
    "delivery": "online",
    "lecturers": "Doc. Dr. Deividas Šlekys Doc. Dr. Deividas Šlekys",
    "language": "lt",
    "exam": false,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=3C9EE76E3F8686193689BCE7C1887C7F8564E23FE5499709"
  },
  "d4iqD3ppruACoI3SHVrL": {
    "name": "Rising Global Regions: Ideas and Conflicts",
    "faculty": "TSPMI",
    "credits": 5,
    "delivery": "online",
    "lecturers": "Prof. Dr. Ramūnas Vilpišauskas Asist. Dr. Ieva GiedraitytėDoc. Dr. Konstantinas Andrijauskas",
    "language": "lt",
    "exam": false,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=ECEEC6C66BDEE6887FE4824F7D1F9E294A394ECBDB80D914"
  },
  "B2NZLG7XpCwEBzNbGeLa": {
    "name": "Art and Politics",
    "faculty": "TSPMI",
    "credits": 5,
    "delivery": "online",
    "lecturers": "Prof. Dr. (HP) Alvydas Jokubaitis J. Asist. Dr. Viktorija Rimaitė-Beržiūnienė",
    "language": "lt",
    "exam": false,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=52DA1F1396F3A2214121256110F11FB0866A4F3764DE75A8"
  },
  "hYbIuBA7HCbV061m5ERJ": {
    "name": "International Politics",
    "faculty": "TSPMI",
    "credits": 5,
    "delivery": "live",
    "lecturers": "Prof. Dr. Dovilė Jakniūnaitė Prof. Dr. Dovilė JakniūnaitėAsist. Dr. Valentinas Beržiūnas",
    "language": "lt",
    "exam": false,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=5B9FFD889B4025ED570BA10CA0C2C754CFAB3742A7021826"
  },
  "0rCt7927gvbZJsm3fqCQ": {
    "name": "Mass Atrocities & International Intervention",
    "faculty": "TSPMI",
    "credits": 5,
    "delivery": "live",
    "lecturers": "M. D. Dr. Thomas William Peak M. D. Dr. Thomas William Peak",
    "language": "en",
    "exam": false,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=74E4DD7861906ADC9F595AE464DEBA731658966791AC947A"
  },
  "SGVxexiXsL3UwWfFBuhR": {
    "name": "Constitution: Human Rights, Law, State",
    "faculty": "TF",
    "credits": 5,
    "delivery": "live",
    "lecturers": "Lekt. Dr. Johanas Baltrimas Lekt. Dr. Johanas Baltrimas Asist. Dr. Laura Paškevičienė",
    "language": "lt",
    "exam": false,
    "hours": 50,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=F1008F4A1626D34F0DBC82E229450870A5C439049577FE3C"
  },
  "aH0BesN9ECVYC5jSlBW1": {
    "name": "Leadership",
    "faculty": "VM",
    "credits": 5,
    "delivery": "blended",
    "lecturers": "Doc. Dr. Andrius Valickas Partnerystės doc.   Eglė Daunienė",
    "language": "lt",
    "exam": false,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=6894A38EF6A13D9056163ADEFF57706F028E35AC0195D3B4"
  },
  "07w8CqQNuWpICh01jgF2": {
    "name": "Social Entrepreneurship",
    "faculty": "VM",
    "credits": 5,
    "delivery": "blended",
    "lecturers": "Asist. Dr. Danguolė Oželienė Asist. Dr. Ieva Adomaitytė Subačienė  Andželika Rusteikienė",
    "language": "lt",
    "exam": false,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=9E70C70F17FEF546B6CFA0687C57E097114F4220DCAFD856"
  },
  "20gxoD5oukufFmjahHoe": {
    "name": "Principles of Sustainability in Organizations",
    "faculty": "VM",
    "credits": 5,
    "delivery": "online",
    "lecturers": "Prof. Dr. Tadas Gudaitis Prof. Dr. Tadas Gudaitis",
    "language": "lt",
    "exam": false,
    "hours": 48,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=8E33CE7878CC4B625BBC6F271B4024D850C2EEBD98C01807"
  },
  "PH6qdioq80y7MVRskct6": {
    "name": "Entrepreneurship",
    "faculty": "VM",
    "credits": 5,
    "delivery": "blended",
    "lecturers": "Asist. Dr. Danguolė Oželienė Asist. Dr. Danguolė Oželienė",
    "language": "lt",
    "exam": false,
    "hours": 64,
    "link": "https://is.vu.lt/pls/pub/am$bul_adm_app.public_view_bul_sandas?p_sarasas_id=12DF7A1C17BB509E174C0FE600857A7B3918185951E4C965"
  }
}