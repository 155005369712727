import { subjects_lt } from "./scrap/subjects_lt";
import { subjects_en } from "./scrap/subjects_en";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    lng: 'lt',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      lt: {
        translation: {
          subjects: subjects_lt,
          name: "kasBUS",
          navBar: {
            feedback: "Palikti atsiliepimą",
          },
          firstTimeModal: {
            header: "Sveiki!",
            body1:
              "kasBUS platforma dienos šviesą pamatė visai neseniai, o gimė iš asmeninės iniciatyvos padaryti studijų gyvenimą bent šiek tiek skaidresnį ir geresnį.",
            body2:
              "Nuoširdžiai tikiuosi, jog pavyks pagaliau sukurti centralizuotą platformą grįžtamąjam ryšiui, kurią matytų visa universiteto bendruomenė: tiek studentai, tiek dėstytojai ir administracija!",
            body3:
              "Visas pradinis grįžtamojo ryšio kapitalas surinktas iš pavienių per žmones einančių excelio failų ir praeities apklausų. Pasistenkim ir atnaujinkim turimą informaciją, padėsim vieni kitiems!",
            button: "Tęsti",
            disclaimerTemp:
              "Gali būti neatitikimų tarp oficialios BUS pasirinkimų svetainės ir kasBUS - ties platforma dar dirbama, dėkui už supratingumą ;)",
          },
          feedbackModal: {
            header: "Ką manote apie šią platformą? Kur galėtumėme tobulėti?",
            button: "Siųsti",
            toast: "Labai dėkoju!",
            placeholder: "Jūsų komentaras...",
            toastMessage: "Dėkui už grįžtamąjį ryšį <3",
          },
          mainScreen: {
            title:
              "Bendruomenės įgalinta grįžtamojo ryšio platforma VU BUS dalykams.",
            subtitle:
              "Niekam nepatinka rinktis studijų dalykų sėkmės principu, nieko nežinant apie daugiau nei du trečdalius kitų pasirinkimų. Turim progą sujungti savo patirtis tam, jog ateityje studentams nereiktų spėlioti. Būk kasBUS pradžios dalimi!",
            searchBar: "Ieškoti pagal pavadinimą...",
            table: {
              name: "BUS pavadinimas",
              faculty: "Fakultetas",
              lecturers: "Dėstytojai",
              button: "Peržiūrėti",
              empty: "Tokių dalykų neradom :(",
            },
          },
          detailsScreen: {
            button: "Žiūrėti dalyko aprašą",
            credits: "Kreditų skaičius: ",
            language: "Dalyko kalba: ",
            hours: "Valandų skaičius: ",
            commentCard: {
              label: "Jūsų komentaras",
              placeholder: "Palikite savo komentarą...",
              button: "Įrašyti",
              disclaimer: {
                body: "Atsimink, visi tikimės gauti naudingą ir konstruktyvų grįžtamąjį ryšį, o ne spamą.",
              },
              first: "Komentarų dar nėra. Būk pirmas!",
            },
            rating: {
              categories: {
                category1: "Įdomumas",
                category2: "Savarankiško darbo kiekis",
                category3: "Aktualumas",
                category4: "Dėstymas",
              },
              thankYou: "Dėkojame už įvertinimą!",
              yourRating: "Jūsų įvertinimas: ",
            },
            toast:
              "Jūsų komentaras įkeltas! Perkraukite puslapį, kad jį pamatytumėte :)",
            timing: {
              lessThanMin: "prieš mažiau nei minutę",
              generic: "prieš {{x}} ",
              minute1: "minutę",
              minute2: "minučių",
              minute3: "minutes",
              hour1: "valandą",
              hour2: "valandų",
              hour3: "valandas",
              dayAgo: "prieš dieną",
              moreThanDayAgo: "daugiau nei prieš dieną",
              fewDaysAgo: "prieš kelias dienas",
              weekAgo: "maždaug prieš savaitę",
              fewWeeksAgo: "prieš kelais savaites",
              monthAgo: "maždaug prieš mėnesį",
              fewMonthsAgo: "prieš kelis mėnesius",
              halfYearAgo: "prieš maždaug pusmetį",
              lessThanYearAgo: "prieš mažiau nei metus",
              yearAgo: "maždaug prieš metus",
              xYearsAgo1: "`maždaug prieš {{years}} metų",
              xYearsAgo2: "`maždaug prieš {{years}} metus",
            },
          },
          timelineScreen: {
            navbarTitle: "Atieites planai",
            title:
              "Bendruomenės įgalinta grįžtamojo ryšio platforma VU BUS dalykams.",
            subtitle:
              "Iki pilnai paruošto įrankio dar trūksta. Dalinuosi ateities planais kasBUS platformai, taip pat ir jau nuveiktais darbais. Jei turi idėjų kaip galima padaryti viską geriau - duok žinot ir susisiek!",
            point1: {
              title: "kasBUS pradžia",
              time: "Rugsėjis 2022",
              description:
                "Pirmą kartą išbandėm sistemą su VU MIF Informatikos pirmakursiais, kurių jau pirmame semestre laukė BUS pasirinkimas.",
            },
            point2: {
              title: "Išsibandymas už MIF'o sienų, viso VU mastu.",
              time: "Gruodis 2022",
              description:
                "Gruodžio 1 dienos BUS pasirinkimui, be oficialaus viešinimo, parekomendavom platformą mažam kiekiui žmonių, pavyko išplėst auditoriją per daugelį VU fakultetų. Susirinkta daug medžiagos tiek apie studijų dalykus, tiek grįžtamojo ryšio apie pačią platformą.",
            },
            point3: {
              title: "Dirbu, taisau, tuoj bus!",
              time: "Dabar - Sausis 2022",
              description:
                'Darbas ties keliais techniniais aspektais, kurie leis platformai "patempt" tokį didelį srautą žmonių per trumpą laiką. Planas - pabaigt iki semestro galo, surinkt daug nuomonių apie BUS dalykus iš dalykus baigusių žmonių.',
              action: "Turi idėjų? Pasidalink su manim!",
              badge: "Naujausias",
            },
            point4: {
              title: "Semestro pabaiga.",
              time: "Sausis - Vasaris 2023",
              description:
                "Paleidimas MIF mastu, su tikslu susirinkti kuo daugiau grįžtamojo ryšio. Jei viskas ok - galima bus pradėt viešint su tikslu pasiekt visą VU.",
              badge: "Ateityje",
            },
            point5: {
              title: "Tikras, pilnas kasBUS release'as.",
              time: "Gegužė 2023",
              description:
                "Gegužės mėnesio BUS pasirinkimas - paleisiu kasBUS pilnu mastu. Labai tikiuosi, jog viskas iki šio taško pavyks ;)",
              badge: "Ateityje",
            },
            point6: {
              title: "Veiksmas su PD",
              time: "Pavasaris 2023",
              description:
                "Sekantis logiškas žingsnis imtis sudėtingesnio, bet nemažiau prasmingo siekio - integruoti PD dalykus į kasBUS. Tik dar vienas iš dalykų, kuriuos noriu padaryti su kasBUS prieš baigdamas studijas MIF'e.",
              badge: "Ateityje",
            },
          },
          maintenanceScreen: {
            title: "Puslapis tvarkomas",
            subtitle:
              "Džiaugiuosi, kad laikinas prototipas sulaukė tiek dėmesio, tačiau turim truputį techninių nesklandumų. Šiuo metu tvarkome kasBUS, greitu metu grįšime!",
          },
          errorScreen: {
            title: "Tokio puslapio nėra :(",
          },
          footerBar: {
            text: "Susisiek su manim!",
            credit: "Marius Šomka",
          },
        },
      },
      en: {
        translation: {
          subjects: subjects_en,
          name: "kasBUS",
          navBar: {
            feedback: "Leave feedback",
          },
          firstTimeModal: {
            header: "Sveiki!",
            body1:
              "kasBUS platforma dienos šviesą pamatė visai neseniai, o gimė iš asmeninės iniciatyvos padaryti studijų gyvenimą bent šiek tiek skaidresnį ir geresnį.",
            body2:
              "Nuoširdžiai tikiuosi, jog pavyks pagaliau sukurti centralizuotą platformą grįžtamąjam ryšiui, kurią matytų visa universiteto bendruomenė: tiek studentai, tiek dėstytojai ir administracija!",
            body3:
              "Visas pradinis grįžtamojo ryšio kapitalas surinktas iš pavienių per žmones einančių excelio failų ir praeities apklausų. Pasistenkim ir atnaujinkim turimą informaciją, padėsim vieni kitiems!",
            button: "Tęsti",
            disclaimerTemp:
              "Gali būti neatitikimų tarp oficialios BUS pasirinkimų svetainės ir kasBUS - ties platforma dar dirbama, dėkui už supratingumą ;)",
          },
          feedbackModal: {
            header: "What do you think of our platform?",
            placeholder: "Your feedback...",
            toast: "Thanks a lot!",
            button: "Send",
            toastMessage: "Thanks for your feedback <3",
          },
          mainScreen: {
            title:
              "Community based platform for feedback sharing on VU BUS subjects",
            subtitle:
              "Nobody likes choosing their studies based on luck, by not knowing anything about majority of the subjects. We have a great opportunity to combine our knowledge into one. Be a part of kasBUS journey since the first step!",
            searchBar: "Search by name...",
            table: {
              name: "BUS name",
              faculty: "Faculty",
              lecturers: "Lecturers",
              button: "See subject",
              empty: "No subjects found",
            },
          },
          detailsScreen: {
            button: "Go to an official subject description",
            credits: "Amount of credits: ",
            language: "Language: ",
            hours: "Amount of hours: ",
            commentCard: {
              placeholder: "Leave your comment here...",
              button: "Send",
              disclaimer: {
                body: "Remember, all of us expect to find valuable and constructive feedback, not just some random spamą ",
              },
              first:
                "No comments here yet. Be the first one to leave your feedback!",
            },
            rating: {
              categories: {
                category1: "Interest",
                category2: "Amount of work",
                category3: "Actuality",
                category4: "Teaching",
              },
              thankYou: "Thanks for your evaluation!",
              yourRating: "Your evaluation: ",
            },
            toast: "Your comment is uploaded! Refresh the page, to see it :)",
            timing: {
              lessThanMin: "less than a minute ago",
              generic: "{{x}} ",
              minute1: "minute ago",
              minute2: "minutes ago",
              minute3: "minutes ago",
              hour1: "hour ago",
              hour2: "hours ago",
              hour3: "hours ago",
              dayAgo: "a day ago",
              moreThanDayAgo: "more than a day ago",
              fewDaysAgo: "a few days ago",
              weekAgo: "around a week ago",
              fewWeeksAgo: "a few weeks ago",
              monthAgo: "around a month ago",
              fewMonthsAgo: "a few months ago",
              halfYearAgo: "half a year ago",
              lessThanYearAgo: "less than a year ago",
              yearAgo: "around a year ago",
              xYearsAgo1: "around {{years}} years ago",
              xYearsAgo2: "around {{years}} years ago",
            },
          },
          timelineScreen: {
            navbarTitle: "Future plans",
            title:
              "Community based platform for feedback sharing on VU GUS subjects",
            subtitle:
              "Quite some work is still lacking till we can call this a fully functional platform for planned needs. Sharing some of the planned work for future, also some work that has already been done. If you have an idea on how to make this a better experience, please contact me and let me know!",
            point1: {
              title: "The beggining of kasBUS",
              time: "September 2022",
              description:
                "First time we tried the platform out together with VU MIF first year students of Informatics, since they had to select these subjects for their first semester.!",
            },
            point2: {
              title: "Trying it out for the whole VU.",
              time: "December 2022",
              description:
                "Without any official PR, we tried kasBUS out for the subject selection window starting December 1st. As we only recommended it to a small part of students in VU MiF, audience quickly grew bigger and touched most of the faculties in the University. Got a lot of feedback both for the subjects and for the platform instead!",
            },
            point3: {
              title: "Doing the work, will be ready soon!",
              time: "Now - January 2022",
              description:
                "Working on couple of technical scalability aspects of the website, once it's done, kasBUS will be ready to sustain such a traffic in a short period of time! The plan is to finish this for the end of the semester, collect all the possible feedback from students who finished studying those subjects.",
              action: "Have any ideas on how to improve this? Let me know!",
              badge: "Newest",
            },
            point4: {
              title: "End of the semester.",
              time: "January - February 2023",
              description:
                " I'm planning to release this for the whole MIF, trying to get as much feedback. If all goes well, I'll start working on the scope for the whole VU.",
              badge: "Future",
            },
            point5: {
              title: "Full scale release.",
              time: "May 2023",
              description:
                "GUS selection window in May - I'll release it full scale. I hope it all goes well up until this point ;)",
              badge: "Future",
            },
            point6: {
              title: "Action with Elective Courses",
              time: "Spring of 2023",
              description:
                "Next logical step - take upon a task of integrating elective subjects to kasBUS. One of many things I wanna finish before completing my bachelors degree at VU MIF!",
              badge: "Future",
            },
          },
          maintenanceScreen: {
            title: "Under Maintenance",
            subtitle:
              "Our developers (just kidding, it's just me alone who's developing this) are performing scheduled maintenance.",
          },
          errorScreen: {
            title: "Page not found :(",
          },
          footerBar: {
            text: "Contact me!",
            credit: "Marius Šomka",
          },
        },
      },
    },
  });

export { i18n };
