import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CommentCard } from "../components/CommentCard";
import { Language } from "../types";
import { getLanguage } from "../utils/getLanguage";
import { DocumentData } from "firebase/firestore";
import { useTranslation } from "react-i18next";
import { i18n } from "../i18n";
import { useMediaQuery } from "../utils/useMedia";

export const DetailsScreen = () => {
  const { itemId } = useParams();
  const { t } = useTranslation();
  const [subject, setSubject] = useState<DocumentData | null>(null);
  const matches = useMediaQuery("(min-width: 640px)");
  const matches2 = useMediaQuery("(min-width: 420px)");

  async function getSubject() {
    if (itemId) {
      //   const docRef = doc(db, "subjects", itemId);
      //   const docSnap = await getDoc(docRef);
      //   if (docSnap.exists()) {
      //     setSubject(docSnap.data());
      //   }
      const tObj = t(`subjects`, { returnObjects: true }) as any;
      setSubject(tObj[itemId]);
    }
  }

  useEffect(() => {
    getSubject();
  }, [i18n.language]);

  return (
    <div className={`w-full ${matches ? "px-6" : ""}`}>
      <div
        className={`px-6 relative ${
          matches ? "rounded-lg mt-4" : ""
        } bg-rose-800 container mx-auto flex flex-col items-center pt-12 sm:pt-24 pb-24 sm:pb-32 md:pb-48 lg:pb-56 xl:pb-64`}
      >
        <img
          className="mr-2 lg:mr-12 mt-2 lg:mt-12 absolute right-0 top-0"
          src="https://tuk-cdn.s3.amazonaws.com/can-uploader/center_aligned_with_image-svg2.svg"
          alt="bg"
        />

        <div className={`mt-4 w-11/12 sm:w-2/3 mb-5 sm:mb-10`}>
          <h1 className="flex flex-row text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-left text-white font-bold">
            {`${subject?.name} (${subject?.faculty})`}
            {/* <FacultyBadge faculty={subject?.faculty} size={"large"} /> */}
            {/* TODO FIX A11Y */}
          </h1>
        </div>
        <div className="mt-4 w-11/12 sm:w-2/3 mb-2 sm:mb-5">
          <h1 className="text-md sm:text-md md:text-lg lg:text-xl xl:text-xl text-left text-white font-regular">
            {subject?.lecturers}
          </h1>
        </div>
        <div
          className={`flex ${
            matches2 ? "flex-row" : "flex-col"
          } gap-8 w-11/12 sm:w-2/3 mb-5 sm:mb-10`}
        >
          <div className={"flex flex-col align-items-center gap-1"}>
            <div className={"flex gap-2"}>
              <h1 className="text-md sm:text-md md:text-lg lg:text-xl xl:text-xl text-left text-white font-regular">
                {t("detailsScreen.credits")}
              </h1>
              <h1 className="text-md sm:text-md md:text-lg lg:text-xl xl:text-xl text-left text-white font-bold">
                {subject?.credits}
              </h1>
            </div>
            <div className={"flex gap-2"}>
              <h1 className="text-md sm:text-md md:text-lg lg:text-xl xl:text-xl text-left text-white font-regular">
                {t("detailsScreen.language")}
              </h1>
              <h1 className="text-md sm:text-md md:text-lg lg:text-xl xl:text-xl text-left text-white font-bold">
                {getLanguage(subject?.language ?? Language.Lithuanian)}
              </h1>
            </div>
            <div className={"flex gap-2"}>
              <h1 className="mb-6 text-md sm:text-md md:text-lg lg:text-xl xl:text-xl text-left text-white font-regular">
                {t("detailsScreen.hours")}
              </h1>
              <h1 className="text-md sm:text-md md:text-lg lg:text-xl xl:text-xl text-left text-white font-bold">
                {subject?.hours}
              </h1>
            </div>
          </div>
          {/* todo: neveikia link'ai į aprašus */}
          {/* <a
            className={
              "ml-auto whitespace-nowrap w-min h-min text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
            }
            color={"light"}
            href={subject?.link}
            target="_blank"
            rel="noreferrer"
          >
            <h1 className="text-xs sm:text-sm md:text-base lg:text-md xl:text-md text-left text-red font-regular">
              {t("detailsScreen.button")}
            </h1>
          </a> */}
        </div>

        <img
          className="ml-2 lg:ml-12 mb-2 lg:mb-12 absolute bottom-0 left-0"
          src="https://tuk-cdn.s3.amazonaws.com/can-uploader/center_aligned_with_image-svg3.svg"
          alt="bg"
        />
      </div>
      <div
        className={`${
          matches
            ? "mx-auto flex justify-center md:-mt-56 sm:-mt-40 "
            : "mx-auto"
        } container -mt-24`}
      >
        <div className="relative ">
          <CommentCard itemId={itemId ?? ""} />
        </div>
      </div>
    </div>
  );
};
