import { Navbar } from "flowbite-react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FeedbackModal } from "./FeedbackModal";

const lngs = {
  en: { nativeName: "EN" },
  lt: { nativeName: "LT" },
};

export const NavBar = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [displayModal, setDisplayModal] = useState<boolean>(false);
  const [displayMenu, setDisplayMenu] = useState<boolean>(false);

  return (
    <React.Fragment>
      <FeedbackModal
        displayModal={displayModal}
        setDisplayModal={setDisplayModal}
      />
      <Navbar fluid={true} rounded={true}>
        <Navbar.Brand>
          <img
            src={process.env.PUBLIC_URL + 'logo.svg'}
            className="cursor-pointer mr-3 h-6 sm:h-9"
            alt="logo"
            onClick={() => navigate('/')}
          />
          <span onClick={() => navigate('/')} className="cursor-pointer self-center whitespace-nowrap text-xl font-bold dark:text-white">
            kasBUS
          </span>
        </Navbar.Brand>
        <div className="flex md:order-2">
          <Navbar.Toggle />
        </div>
        <Navbar.Collapse style={{ marginLeft: 'auto', marginRight: 12 }}>
          {/* <Navbar.Link onClick={() => navigate('/timeline')}>
            {t('timelineScreen.navbarTitle')}
          </Navbar.Link> */}
          <Navbar.Link style={{ cursor: 'pointer' }} onClick={() => setDisplayModal(true)}>{t("navBar.feedback")}</Navbar.Link>
          {Object.keys(lngs).map((lng) => (
            <Navbar.Link
              key={lng}
              style={{
                fontWeight: i18n.resolvedLanguage === lng ? "bold" : "normal",
                cursor: 'pointer'
              }}
              type="submit"
              onClick={() => i18n.changeLanguage(lng)}
            >
              {lngs[lng as "en" | "lt"].nativeName}
            </Navbar.Link>
          ))}
        </Navbar.Collapse>
      </Navbar>
    </React.Fragment >
  );
};
{
  /* <React.Fragment>
      <FeedbackModal displayModal={displayModal} setDisplayModal={setDisplayModal}/>
      <div className={"position: fixed top-0 w-full z-10"}>
        <Navbar fluid={false} rounded={true}>
          <Navbar.Brand href="/">
            <img
              src="https://flowbite.com/docs/images/logo.svg"
              className="mr-3 h-6 sm:h-9"
              alt="Flowbite Logo"
            />
            <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">
              kasBUS
            </span>
          </Navbar.Brand>
            <button className="mr-12 ml-auto" onClick={() => setDisplayModal(true)}>
              {t('navBar.feedback')}
            </button>
          <div className="grid gap-4 grid-cols-2">
            {Object.keys(lngs).map((lng) => (
              <button
                key={lng}
                style={{
                  fontWeight: i18n.resolvedLanguage === lng ? "bold" : "normal",
                }}
                type="submit"
                onClick={() => i18n.changeLanguage(lng)}
              >
                {lngs[lng as "en" | "lt"].nativeName}
              </button>
            ))}
          </div>
        </Navbar>
      </div>
    </React.Fragment> */
}
