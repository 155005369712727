import { Button, Modal } from "flowbite-react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export const FirstTimeModal = () => {
  const { t } = useTranslation();
  const [display, setDisplay] = useState<boolean>(true);
  const onClick = () => {
    setDisplay(!display);
    document.cookie = "modalShown=true; SameSite=None; Secure; Expires=Fri, 1 Mar 2024 12:00:00 GMT";
  };

  return (
    <Modal show={display} size="md" popup={true} onClose={onClick}>
      <Modal.Header />
      <Modal.Body>
        <div className="text-center">
          <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
            {t("firstTimeModal.header")}
          </h3>
            <p className="mb-5 text-md font-normal text-gray-500 dark:text-gray-400">
              {t("firstTimeModal.body1")}
            </p>
            <p className="mb-5 text-md font-normal text-gray-500 dark:text-gray-400">
              {t("firstTimeModal.body2")}
            </p>
            <p className="mb-5 text-md font-normal text-gray-500 dark:text-gray-400">
              {t("firstTimeModal.body3")}
            </p>
          <h3 className="mb-5 text-md font-bold text-gray-500 dark:text-gray-400">
            {t("firstTimeModal.disclaimerTemp")}
          </h3>
          <div className="flex justify-center gap-4">
            <Button color="failure" onClick={onClick}>
              {t("firstTimeModal.button")}
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
