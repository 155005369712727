import React from "react";
import { Footer } from "flowbite-react";
import { useTranslation } from "react-i18next";

export const FooterBar = () => {
  const { t } = useTranslation();
  return (
      <div className="mt-28 w-full bottom-0">
      <Footer container={true}>
        <Footer.Copyright by={`kasBUS, ${t('footerBar.credit')}`} year={2022} />
        <Footer.LinkGroup>
          <Footer.Link href="https://www.facebook.com/marius.somka" >{t('footerBar.text')}</Footer.Link>
        </Footer.LinkGroup>
      </Footer>
    </div>
  );
};
