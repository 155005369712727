import React from "react";
import { useTranslation } from "react-i18next";
import { MobileTableList } from "../components/MobileTableList";
import { TableList } from "../components/TableList";
import { useMediaQuery } from "../utils/useMedia";

export const MainScreen = () => {
  const { t } = useTranslation();
  const matches = useMediaQuery("(min-width: 640px)");

  return (
    <>
      <div className={`w-full ${matches ? `px-6` : ``}`}>
        <div
          className={`mt-4 relative ${
            matches ? `rounded-lg` : `mt-0`
          } bg-rose-800 container mx-auto flex flex-col items-center pt-12 sm:pt-24 pb-24 sm:pb-32 md:pb-48 lg:pb-56 xl:pb-64`}
        >
          <img
            className="mr-2 lg:mr-12 mt-2 lg:mt-12 absolute right-0 top-0"
            src="https://tuk-cdn.s3.amazonaws.com/can-uploader/center_aligned_with_image-svg2.svg"
            alt="bg"
          />
          <img
            className="ml-2 lg:ml-12 mb-2 lg:mb-12 absolute bottom-0 left-0"
            src="https://tuk-cdn.s3.amazonaws.com/can-uploader/center_aligned_with_image-svg3.svg"
            alt="bg"
          />
          <div className="sm:w-2/3 mb-5 sm:mb-10">
            <h1 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-center text-white font-bold leading-tight">
              {t("mainScreen.title")}
            </h1>
          </div>
          <div className="flex justify-center items-center sm:mb-20">
            <p className="mx-4 md:mx-10 text-xs sm:text-sm md:text-md lg:text-md xl:text-lg text-center text-white leading-tight">
              {t("mainScreen.subtitle")}
            </p>
          </div>
        </div>
        {matches ? (
          <div className="container mx-auto flex justify-center md:-mt-64 -mt-40 sm:-mt-50">
            <div className="relative w-10/12">
              <TableList />
            </div>
          </div>
        ) : (
          <div className="container mx-auto flex justify-center">
            <div className="relative">
              <MobileTableList />
            </div>
          </div>
        )}
      </div>
    </>
  );
};
