import {
  addDoc,
  collection,
  DocumentData,
  getDocs,
  query,
  QuerySnapshot,
  where,
} from "firebase/firestore";
import { Spinner, Tooltip } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Rating } from "react-simple-star-rating";
import { db } from "../App";
import { ErrorScreen } from "../screens/ErrorScreen";
import { RatingCategory } from "../types";
import { useMediaQuery } from "../utils/useMedia";

type Props = {
  category: RatingCategory;
  itemId: string;
  addTooltip?: boolean;
};

export const Ratings = ({ category, itemId, addTooltip }: Props) => {
  const [scoreLoading, setScoreLoading] = useState<boolean>(true);
  const [score, setScore] = useState<number>(0);
  const [rating, setRating] = useState<number>(0);
  const [isLocked, setIsLocked] = useState<boolean>(false);
  const { t } = useTranslation();
  const matches = useMediaQuery("(min-width: 440px)");
  const matches2 = useMediaQuery("(min-width: 1040px)");

  const writeData = async () => {
    await addDoc(collection(db, "ratings"), {
      subjectId: itemId,
      category,
      rating,
      timestamp: new Date(),
    });
  };

  const handleRatingSubmit = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    category: RatingCategory,
    rating: number
  ) => {
    event.preventDefault();
    setIsLocked(true);
    // console.log({ itemId, category, rating });
    await writeData();
    getScore();
  };

  // Catch Rating value
  const handleRating = (rate: number) => {
    setRating(rate);
  };

  const getTitle = () => {
    return t(`detailsScreen.rating.categories.${category}`);
  };

  async function getScore() {
    if (itemId) {
      const q = query(
        collection(db, "ratings"),
        where("subjectId", "==", itemId),
        where("category", "==", category)
      );

      let querySnapshot: QuerySnapshot<DocumentData>;
      querySnapshot = await getDocs(q);

      let sum = 0;
      querySnapshot.forEach((snap) => {
        const { rating } = snap.data();
        sum += rating;
      });

      if (querySnapshot.size > 0) {
        setScore(+(sum / querySnapshot.size).toFixed(2));
      } else setScore(0);
    }
  }

  const displayScore = () => {
    if (scoreLoading) {
      return <Spinner color={"failure"} />;
    }
    if (score === 0) {
      return "-";
    } else return score;
  };

  useEffect(() => {
    getScore().then(() => setScoreLoading(false));
  }, []);

  if (!itemId) {
    return <ErrorScreen />;
  }

  return (
    <div
      className={`px-5 mx-auto pt-5 pb-3 w-full max-w-sm bg-white rounded-lg border border-gray-200 shadow-md  flex flex-col ${
        matches2 ? "" : "mb-8"
      }`}
    >
      <div className="flex">
      <h1 className="text-base font-bold sm:text-md md:text-lg lg:text-xl xl:text-2xl text-left text-gray-700 font-regular">
        {getTitle()}
      </h1>
      {addTooltip && <Tooltip content="1 - darbo per daug; 5 - darbo kiekis tinkamas">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          className="w-6 h-6 mt-1 ml-2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
          />
        </svg>
      </Tooltip>}
      </div>
      <h1 className="text-xl sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl text-left text-gray-700 font-bold">
        {displayScore()}
      </h1>
      {isLocked ? (
        <p className="mb-5 mt-5 ml-1 italic text-sm text-gray-900 dark:text-white">
          {t("detailsScreen.rating.thankYou")}
        </p>
      ) : (
        <div className={matches ? `grid grid-cols-2` : `grid grid-cols-1`}>
          <div>
            <div className="flex flex-row">
              <p className="mt-3 mb-1 ml-1  text-sm text-gray-900 dark:text-white">
                {t("detailsScreen.rating.yourRating")}
              </p>
              {rating > 0 ? (
                <p className="mt-3 mb-1 ml-1 font-bold  text-sm text-gray-900 dark:text-white">
                  {rating}
                </p>
              ) : (
                <></>
              )}
            </div>
            <div className="flex items-center h-max">
              <Rating
                onClick={handleRating}
                ratingValue={rating}
                size={20}
                transition
                fillColor="orange"
                emptyColor="gray"
              />
            </div>
          </div>
          {rating > 0 ? (
            <button
              type="submit"
              className={`relative h-min mb-1 ${
                matches ? `mr-0 ml-auto mt-auto` : `ml-0 mt-4 mr-auto`
              } inline-flex items-center py-2.5 px-4 text-xs font-medium text-center text-white bg-rose-700 rounded-lg focus:ring-4 focus:ring-rose-200 dark:focus:ring-rose-900 hover:bg-rose-800`}
              onClick={(e) => handleRatingSubmit(e, category, rating)}
            >
              {t("detailsScreen.commentCard.button")}
            </button>
          ) : (
            <></>
          )}
        </div>
      )}
    </div>
  );
};
